import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { Partner } from '../../models/Partner';
import './DropdownBrands.scss';

interface Props {
  selectedPartner: Partner | null;
  setSelectedPartner: (partner: Partner) => void;
}

const DropdownBrands: React.FC<Props> = ({
  selectedPartner,
  setSelectedPartner,
}) => {
  const { partners } = useAppSelector(state => state.partners);

  const [toggleSelect, setToggleSelect] = useState(false);

  const handleLiClick = (index: number) => {
    setSelectedPartner(partners[index]);
    setToggleSelect(false);
  };

  const toggleSelectedClass = () => {
    setToggleSelect(!toggleSelect);
  };

  useEffect(() => {
    if (partners.length > 0) {
      setSelectedPartner(partners[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners]);

  return (
    partners?.length > 0 && (
      <div className='dropdown-brands-container'>
        <div className={`content-select ${toggleSelect ? 'open' : ''}`}>
          <div className='header-select' onClick={toggleSelectedClass}>
            {/* <img
            src={
              selectedOption ? selectedOption.imagePath : partners[0].imagePath
            }
            alt=''
          /> */}
            {selectedPartner ? selectedPartner.title : partners[0].title}
          </div>
          <div className='options-select'>
            <ul>
              {partners.map((partner: Partner, index) => (
                <li key={index} onClick={() => handleLiClick(index)}>
                  {/* <img src={partner.imagePath} alt='' /> */}
                  {partner.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

export default DropdownBrands;
