import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { OrganizationSlotStatistic } from '../../models/OrganizationSlotsStatistics';
import Spinner from '../Spinner';

interface ChartData {
  options: ApexCharts.ApexOptions;
  series: { name: string; type: 'bar'; data: (number | null)[] }[];
}

const UsageChart: React.FC<{ data: OrganizationSlotStatistic[] }> = ({
  data,
}) => {
  const [chartData, setChartData] = useState<ChartData | null>(null);

  useEffect(() => {
    if (!data || data.length === 0) {
      setChartData({
        options: {
          chart: {
            type: 'line',
            toolbar: { show: false },
          },
          xaxis: {
            categories: [moment().format('YYYY-MM-DD')],
            labels: { rotate: -45 },
          },
          yaxis: {
            title: { text: 'Usage' },
          },
          stroke: { width: [0, 3] },
          colors: ['#1E90FF', 'darkred'],
          dataLabels: { enabled: false },
          legend: { position: 'top' },
        },
        series: [
          {
            name: 'Usage',
            type: 'bar',
            data: [],
          },
        ],
      });
      return;
    }

    setChartData(processData(data));
  }, [data]);

  const processData = (data: OrganizationSlotStatistic[]): ChartData => {
    const usageMap = new Map<string, number>();
    const startDate = moment(data[0].usageDate);
    const endDate = moment(data[data.length - 1].usageDate);
    const currentDate = moment(startDate);
    const labels = [];
    const usageValues = [];

    data.forEach(({ usage, usageDate }) => {
      usageMap.set(usageDate, usage);
    });

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, 'day')
      ) {
      const dateStr = currentDate.format('YYYY-MM-DD');
      labels.push(dateStr);
      usageValues.push(usageMap.get(dateStr) ?? 0);
      currentDate.add(1, 'days');
    }

    return {
      options: {
        chart: {
          type: 'line',
          toolbar: { show: false },
        },
        xaxis: {
          categories: labels,
          labels: { rotate: -45 },
        },
        yaxis: {
          title: { text: 'Usage' },
        },
        stroke: { width: [0, 3] },
        colors: ['#1E90FF', 'darkred'],
        dataLabels: { enabled: false },
        legend: { position: 'top' },
      },
      series: [
        {
          name: 'Usage',
          type: 'bar',
          data: usageValues,
        },
      ],
    };
  };

  return (
    <div style={{ width: '100%', maxWidth: '900px', margin: 'auto' }}>
      {chartData ? (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type='line'
          height={400}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default UsageChart;
