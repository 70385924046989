import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPublicProperty } from '../../redux/properties/propertiesThunk';
import './PublicProperty.scss';
import { getFilePath, getPriceType, getWebUrl } from '../../helpers/useHelper';

const PublicProperty: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { publicProperty, loading } = useAppSelector(state => state.properties);

  const image = getFilePath(publicProperty?.media?.[0]?.path ?? '')
  const priceItem = publicProperty?.prices?.find(price => price.status === 1);
  const url = getWebUrl(`public/properties/${publicProperty?.id}`);

  useEffect(() => {
    if (id) {
      dispatch(getPublicProperty(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className='display-flex flex-column main-wrapper'>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Helmet>
            <title>{publicProperty?.title}</title>
            <meta property='og:title' content={publicProperty?.title} />
            <meta
              property='og:description'
              content={publicProperty?.description}
            />
            <meta
              property='og:image'
              content={image}
            />
            <meta property='og:url' content={url} />
            <meta property='og:type' content='website' />
          </Helmet>
          <div className='flex-container display-flex'>{publicProperty?.title}</div>
          <div className='flex-container display-flex'>{publicProperty?.categoryTitle}</div>
          <div className='flex-container display-flex'>Tip tranzactie: {getPriceType(priceItem?.id)}</div>
          {priceItem?.price && <div className='flex-container display-flex'>{`Pret: ${priceItem?.price ?? ''} ${publicProperty?.currencySymbol ?? ''}`}</div>}
        </>
      )}
    </div>
  );
};

export default PublicProperty;
