import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from './redux/store';
import Navigation from './Navigation';
import './styles/App.scss';

function App() {
  return (
    <HelmetProvider>
      <div className='App container main-container'>
        <Provider store={store}>
          <Navigation />
        </Provider>

        <ToastContainer />
      </div>
    </HelmetProvider>
  );
}

export default App;
