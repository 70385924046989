interface Props {}

const IconShare: React.FC<Props> = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5671 10.5897C10.8484 10.5897 10.2009 10.9 9.75151 11.3937L5.70935 8.89022C5.81727 8.61396 5.87712 8.3139 5.87712 7.99998C5.87712 7.68596 5.81727 7.3859 5.70935 7.10975L9.75151 4.60613C10.2009 5.09983 10.8484 5.41031 11.5671 5.41031C12.9209 5.41031 14.0223 4.30893 14.0223 2.9551C14.0223 1.60126 12.9209 0.5 11.5671 0.5C10.2133 0.5 9.1119 1.60138 9.1119 2.95521C9.1119 3.26912 9.17187 3.56918 9.27967 3.84544L5.23762 6.34895C4.78822 5.85525 4.14071 5.54477 3.42202 5.54477C2.06819 5.54477 0.966812 6.64627 0.966812 7.99998C0.966812 9.35382 2.06819 10.4552 3.42202 10.4552C4.14071 10.4552 4.78822 10.1448 5.23762 9.65102L9.27967 12.1545C9.17187 12.4308 9.1119 12.7308 9.1119 13.0449C9.1119 14.3986 10.2133 15.5 11.5671 15.5C12.9209 15.5 14.0223 14.3986 14.0223 13.0449C14.0223 11.691 12.9209 10.5897 11.5671 10.5897ZM10.0072 2.95521C10.0072 2.09507 10.707 1.39527 11.5671 1.39527C12.4272 1.39527 13.1271 2.09507 13.1271 2.95521C13.1271 3.81535 12.4272 4.51515 11.5671 4.51515C10.707 4.51515 10.0072 3.81535 10.0072 2.95521ZM3.42202 9.55993C2.56177 9.55993 1.86197 8.86012 1.86197 7.99998C1.86197 7.13985 2.56177 6.44004 3.42202 6.44004C4.28216 6.44004 4.98185 7.13985 4.98185 7.99998C4.98185 8.86012 4.28216 9.55993 3.42202 9.55993ZM10.0072 13.0448C10.0072 12.1846 10.707 11.4848 11.5671 11.4848C12.4272 11.4848 13.1271 12.1846 13.1271 13.0448C13.1271 13.9049 12.4272 14.6047 11.5671 14.6047C10.707 14.6047 10.0072 13.9049 10.0072 13.0448Z" fill="#4F6E91"/>
    </svg>
  );
};

export default IconShare;
