import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { scanCi } from '../../redux/contracts/contractsThunk';
import { useAppDispatch } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import './ScanDocument.scss';

interface Props {}

const ScanDocument: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { token } = useParams();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const validToken = token ? localStorage.getItem(token) : false;

    if (validToken) {
      setValid(false);
    }
    //eslint-disable-next-line
  }, []);

  const handleOpenCamera = () => {
    if (fileInputRef.current) {
      setLoading(true);
      fileInputRef.current.click();
    }
  };

  const scanCiFunction = async (file: File) => {
    if (token) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('token', token);

        const resultScan = await dispatch(scanCi(formData));
        unwrapResult(resultScan);

        if (resultScan?.payload?.data) {
          toast.success('Actul a fost scanat cu succes!');
          setLoaded(true);
          localStorage.setItem(token, 'loaded');
        }
      } catch (err: any) {
        diplayErrorMessages(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      scanCiFunction(file);
    }
  };

  return (
    <div className='flex-container display-flex'>
      <div className='display-flex flex-column main-wrapper'>
        <div className='header-menu'>
          <div className='header-container display-flex'>
            <div className='header-title'>
              <h2>Scanare act de identitate</h2>
            </div>
          </div>
        </div>
        <div className='header-container-mobile display-flex'>
          <div className='title'>Scanare act de identitate</div>
        </div>

        <div className='display-flex flex-wrap flex-column items-center scan__wrapper'>
          {valid ? (
            loaded ? (
              <h3>Actul a fost scanat cu succes!</h3>
            ) : (
              <>
                <button
                  className='button-blue button-md'
                  onClick={() => handleOpenCamera()}
                >
                  {loading ? (
                    <Spinner className='btn-blue-spinner' />
                  ) : (
                    'Incepe scanarea'
                  )}
                </button>
                <input
                  type='file'
                  accept='image/*'
                  capture='environment'
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ visibility: 'hidden', position: 'absolute' }}
                />
              </>
            )
          ) : (
            <h3>Link is not valid!</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanDocument;
