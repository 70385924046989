import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getContract,
  getContracts,
  postContractSign,
  postSignCallback,
} from '../../redux/contracts/contractsThunk';
import { useAppDispatch } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import IconTimes from '../../icons/IconTimes';
import Spinner from '../Spinner';
import './ModalSign.scss';

interface Props {
  showModal: boolean;
  id?: number;
  hideModal: () => void;
  refresh?: boolean;
}

const ModalSign: React.FC<Props> = ({ id, showModal, hideModal, refresh }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const [loading, setLoading] = useState(true);
  const [signData, setSignData] = useState<any>(null);
  const [url, setUrl] = useState('');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  let isToastShown = false;

  useEffect(() => {
    if (id) {
      getSignData();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (signData) {
      const intervalId = setInterval(async () => signCallback(), 1000);
      const intervalIframeId = setInterval(async () => getIframeSrc(), 500);
      return () => {
        clearInterval(intervalId);
        clearInterval(intervalIframeId);
      };
    }

    // eslint-disable-next-line
  }, [signData]);

  const getIframeSrc = () => {
    if (iframeRef.current && url !== signData?.client?.redirectUrl) {
      try {
        const iframeSrc =
          iframeRef.current.contentWindow?.location?.href ?? null;

        if (iframeSrc && iframeSrc?.includes(window.location.origin ?? '')) {
          setUrl(signData?.client?.redirectUrl);
        }
      } catch (error) {}
    }
  };

  const getSignData = async () => {
    try {
      const response = await dispatch(postContractSign(id));
      unwrapResult(response);

      if (!response.payload.error) {
        setSignData(response.payload);
        setUrl(response?.payload?.employee?.redirectUrl);
      }
    } catch (err: any) {
      diplayErrorMessages(err);
      hideModal();
      navigate('/edit-contract/' + id);
    } finally {
      setLoading(false);
    }
  };

  const signCallback = async () => {
    if (signData?.envelopeId) {
      try {
        const response = await dispatch(postSignCallback(signData?.envelopeId));
        unwrapResult(response);

        if (response.payload.status === 'completed' && !isToastShown) {
          toast.success('Contractul a fost semnat cu success!');
          hideModal();
          dispatch(getContract(id));
          if (refresh) dispatch(getContracts({}));
          isToastShown = true;
        }
      } catch (err: any) {
        // diplayErrorMessages(err);
      }
    }
  };

  const iframe = useMemo(
    () => (
      <iframe
        src={url}
        ref={iframeRef}
        width={'100%'}
        height={'100%'}
        title='Example Iframe'
      ></iframe>
    ),
    [url],
  );

  return (
    <>
      <Modal
        show={showModal}
        onHide={hideModal}
        animation={false}
        className='sign-modal'
      >
        <button
          className='close-modal'
          aria-label='Închide'
          onClick={hideModal}
        >
          <IconTimes />
        </button>
        <Modal.Body>
          <div
            style={{
              width: '100%',
              height: '500px',
            }}
          >
            {loading ? <Spinner /> : iframe}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalSign;
