import { configureStore } from '@reduxjs/toolkit';
import activitiesSlice from './activities/activities-slice';
import announcesSlice from './announces/announces-slice';
import authSlice from './auth/auth-slice';
import categoriesSlice from './categories/categories-slice';
import citizenshipsSlice from './citizenships/citizenships-slice';
import companiesSlice from './companies/companies-slice';
import contactsSlice from './contacts/contacts-slice';
import contractTemplatesSlice from './contractTemplates/contractTemplates-slice';
import contractsSlice from './contracts/contracts-slice';
import currenciesSlice from './currencies/currencies-slice';
import dashboardSlice from './dashboard/dashboard-slice';
import emailTemplatesSlice from './emailTemplates/emailTemplates-slice';
import evaluationsSlice from './evaluations/evaluations-slice';
import labelsSlice from './labels/labels-slice';
import languagesSlice from './language/language-slice';
import localitiesSlice from './localities/localities-slice';
import mediaSlice from './media/media-slice';
import notificationsSlice from './notifications/notifications-slice';
import permissionsSlice from './permissions/permissions-slice';
import propertiesSlice from './properties/properties-slice';
import reportsSlice from './reports/reports-slice';
import requestsSlice from './requests/requests-slice';
import residentialComplexesSlice from './residentialComplexes/residentialComplexes-slice';
import specializationSlice from './specialization/specialization-slice';
import suggestionsSlice from './suggestions/suggestions-slice';
import usersSlice from './users/users-slice';
import partnersSlice from './partners/partners-slice';
import organizationSlotsStatisticsSlice from './organizationSlotsStatistics/organizationSlotsStatistics-slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    categories: categoriesSlice,
    properties: propertiesSlice,
    localities: localitiesSlice,
    reports: reportsSlice,
    users: usersSlice,
    activities: activitiesSlice,
    media: mediaSlice,
    languages: languagesSlice,
    specializations: specializationSlice,
    contacts: contactsSlice,
    companies: companiesSlice,
    emailTemplates: emailTemplatesSlice,
    permissions: permissionsSlice,
    residentialComplexes: residentialComplexesSlice,
    requests: requestsSlice,
    currencies: currenciesSlice,
    announces: announcesSlice,
    notifications: notificationsSlice,
    dashboard: dashboardSlice,
    labels: labelsSlice,
    contracts: contractsSlice,
    citizenships: citizenshipsSlice,
    contractTemplates: contractTemplatesSlice,
    evaluations: evaluationsSlice,
    suggestions: suggestionsSlice,
    partners:partnersSlice,
    organizationSlotsStatistics:organizationSlotsStatisticsSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
