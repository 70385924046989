import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from 'react-share';
import { getApiUrl } from '../../helpers/useHelper';
import { Property } from '../../models/Properties';
import IconClose from '../../icons/IconClose';
import './ShareModal.scss';

interface Props {
  property: Property;
  isModalOpen: boolean;
  closeModal: () => void;
}

const ShareModal: React.FC<Props> = ({ isModalOpen, closeModal, property }) => {
  const shareUrl = getApiUrl(`property/?id=${property.id}`);

  const handleShareOnWhatsApp = () => {
    window.open(`whatsapp://send?text=${shareUrl}`);
  };

  const handleShareOnTelegram = () => {
    window.open(`https://telegram.me/share/url?url=${shareUrl}&text=${property.title}`);
  };

  const handleShareOnViber = () => {
    window.open(`viber://forward?text=${shareUrl}`);
  };

  const handleShareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`);
  };

  const handleShareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/shareArticle/?url=${encodeURIComponent(shareUrl)}&mini=true&title=${property.title}`);
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={closeModal}
      animation={true}
      className={'share-modal'}
    >
      <Modal.Body>
        <div className='share-modal__top'>
          <p>Distribuie proprietatea</p>
          <div className='close-btn-modal' onClick={closeModal}>
            <IconClose />
          </div>
        </div>
        <div className='share-modal__form'>
          <button
            className='share-modal__button'
            onClick={handleShareOnFacebook}
          >
            <table>
              <tbody>
              <tr>
                <td>
                  <FacebookIcon size={32} round />
                </td>
                <td>Share on Facebook</td>
              </tr>
              </tbody>
            </table>
          </button>
          <button
            className='share-modal__button'
            onClick={handleShareOnViber}
          >
            <table>
              <tbody>
              <tr>
                <td>
                  <ViberIcon size={32} round />
                </td>
                <td>Share on Viber</td>
              </tr>
              </tbody>
            </table>
          </button>
          <button
            className='share-modal__button'
            onClick={handleShareOnTelegram}
          >
            <table>
              <tbody>
              <tr>
                <td>
                  <TelegramIcon size={32} round />
                </td>
                <td>Share on Telegram</td>
              </tr>
              </tbody>
            </table>
          </button>
          <button
            className='share-modal__button'
            onClick={handleShareOnWhatsApp}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <WhatsappIcon size={32} round />
                  </td>
                  <td>Share on WhatsApp</td>
                </tr>
              </tbody>
            </table>
          </button>
          <button
            className='share-modal__button'
            onClick={handleShareOnLinkedIn}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <LinkedinIcon size={32} round />
                  </td>
                  <td>Share on LinkedIn</td>
                </tr>
              </tbody>
            </table>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
