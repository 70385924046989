import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPartners, getSlots } from '../../redux/partners/partnersThunk';
import { Partner } from '../../models/Partner';
import IconArrowUpRight from '../../icons/IconArrowUpRight';
import DropdownBrands from '../DropdownBrands';
import Spinner from '../Spinner';
import './AlocatedSlots.scss';

interface Props {}

const AlocatedSlots: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector(state => state.auth);
  const { partners, slots, slotsLoading } = useAppSelector(
    state => state.partners,
  );
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(
    partners[0],
  );

  useEffect(() => {
    dispatch(getPartners({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPartner?.id) {
      dispatch(getSlots(selectedPartner.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  return (
    partners.length > 0 && (
      <div className='alocated-slots-container'>
        <div className='table-item alocated-list-table'>
          <div className='slots-header display-flex'>
            <h5 className='title-section'>Sloturi alocate</h5>
            <div className='alocated-filters'>
              <DropdownBrands
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
              />
              <Link to='/slots' className='button-blue button-md'>
                Alocare sloturi
              </Link>
            </div>
          </div>
          {slotsLoading ? (
            <div
              style={{
                minHeight: '15rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div className='alocated-table'>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Agent</th>
                    {selectedPartner?.packages?.map(pkg => (
                      <th>
                        <span>{pkg.title}</span>
                      </th>
                    ))}
                  </tr>
                  <tr className='totals-row'>
                    <th>Total Alocate</th>

                    {slots?.total?.map(totalsItem => (
                      <th>
                        <div className='total-item'>
                          <span className='total-value'>
                            {totalsItem?.allocated}
                          </span>
                          <p className='total-left'>{totalsItem?.used}</p>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(auth?.roles?.includes('AGENCY')
                    ? slots?.userSlots
                    : slots?.userSlots.filter(user => user.userId === auth?.id)
                  )?.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/edit-agent/${user.userId}`}>
                          {user.fullName}
                          <IconArrowUpRight />
                        </Link>
                      </td>
                      {user?.partnerPackages?.map(pkg => (
                        <td>
                          <span>
                            {pkg.used ?? 0}/{pkg.value ?? 0}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default AlocatedSlots;
