import * as locale from 'date-fns/locale';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-date-range';
import ReactDatePicker from 'react-datepicker';
import { getPricesTypes } from '../../redux/evaluations/evaluationsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearStreets,
  clearTowns,
  clearZona,
} from '../../redux/localities/localities-slice';
import {
  getCounty,
  getLocalities,
  getLocations,
  getSpecificTypes,
  getStreet,
  getZona,
} from '../../redux/localities/localitiesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import { parseValues } from '../../helpers/useHelper';
import { Filter, SelectValue } from '../../models/Properties';
import IconArrowUp from '../../icons/IconArrowUp';
import {
  contactData,
  roomsData,
} from '../FilterCategory/FilterForm/FiltersForm';
import FormSelect from '../FormSelect';
import Input from '../Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import Spinner from '../Spinner';
import './FilterListMap.scss';

interface Props {
  filtersForm?: any;
  setFiltersForm?: (e: any) => void;
  activeCategory?: number;
  setActiveCategory?: (e: number) => void;
}

const FilterListMap: React.FC<Props> = ({
  filtersForm,
  setFiltersForm,
  activeCategory,
  setActiveCategory,
}) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<any>(
    filtersForm?.length > 0
      ? {
          ...filtersForm.map((filter: any, index: number) => filter),
        }
      : {},
  );
  const [countyId, setCountyId] = useState<string | null>(null);
  const [townId, setTownId] = useState<string | null>(null);
  const { pricesTypes } = useAppSelector(state => state.properties);

  const { filters, loadingFilters, loading } = useAppSelector(
    state => state.announces,
  );

  const { users } = useAppSelector(state => state.users);
  const { categories } = useAppSelector(state => state.categories);
  const { locations, localities, county, streets, zona, specificTypes } =
    useAppSelector(state => state.localities);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [openedFilters, setOpenedFilters] = useState<boolean>(false);

  const filterTypeField = 'adTypeView';

  const myRef = useRef<any>();

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getSpecificTypes());
    dispatch(getUsers({}));
    dispatch(getPricesTypes());
    dispatch(getCounty({ parent: 0 }));
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile) setOpenedFilters(true);
  }, [isMobile]);

  useEffect(() => {
    if (!formData) return;

    let town = filters?.find(
      (filter: Filter) => filter?.[filterTypeField] === 'TOWN',
    )?.id;
    let street = filters?.find(
      (filter: Filter) => filter?.[filterTypeField] === 'STREET',
    )?.id;
    let zona = filters?.find(
      (filter: Filter) => filter?.[filterTypeField] === 'ZONE',
    )?.id;

    if (countyId && town !== undefined) {
      dispatch(getLocalities({ parent: countyId })).then(res => {
        if (parseValues(res.payload)?.length > 0) {
          let clearObject = {};
          if (town)
            clearObject = {
              ...clearObject,
              [town]: parseValues(res.payload)[0],
            };
          if (street) clearObject = { ...clearObject, [street]: [] };
          if (zona) clearObject = { ...clearObject, [zona]: [] };
          clearObject && setFormData({ ...formData, ...clearObject });
          setTownId(parseValues(res.payload)[0].value);
        }
      });
    } else {
      dispatch(clearTowns());
      dispatch(clearStreets());
      dispatch(clearZona());
      let clearObject = {};
      if (town) clearObject = { ...clearObject, [town]: null };
      if (street) clearObject = { ...clearObject, [street]: [] };
      if (zona) clearObject = { ...clearObject, [zona]: [] };
      clearObject && setFormData({ ...formData, ...clearObject });

      setTownId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyId]);

  useEffect(() => {
    if (!formData) return;

    let street = filters?.find(
      (filter: Filter) => filter?.[filterTypeField] === 'STREET',
    )?.id;
    let zona = filters?.find(
      (filter: Filter) => filter?.[filterTypeField] === 'ZONE',
    )?.id;
    if (townId && zona !== undefined) {
      dispatch(getZona({ parent: townId }));
    }
    if (townId && street !== undefined) {
      dispatch(getStreet({ town: townId }));

      let clearObject = {};

      if (street) clearObject = { ...clearObject, [street]: [] };
      if (zona) clearObject = { ...clearObject, [zona]: [] };
      clearObject && setFormData({ ...formData, ...clearObject });
    } else {
      dispatch(clearStreets());
      dispatch(clearZona());
    }
    let clearObject = {};
    if (street) clearObject = { ...clearObject, [street]: [] };
    if (zona) clearObject = { ...clearObject, [zona]: [] };
    clearObject && setFormData({ ...formData, ...clearObject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townId]);

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const handleSelect = (id: number, ranges: any) => {
    setFormData({
      ...formData,
      [id]: {
        ...formData[id],
        from: moment(ranges.selection?.startDate).format('YYYY-MM-DD'),
        to:
          moment(ranges.selection?.endDate).format('YYYY-MM-DD') + ' 23:59:59',
      },
    });
  };

  const handleChange = (
    key: string | number,
    e: string | number | SelectValue | boolean | null | string[],
  ) => {
    setFormData({ ...formData, [key]: e });
  };

  const handleChangeRange = (
    key: string | number,
    object: string,
    e: string | number | boolean | SelectValue,
  ) => {
    setFormData({ ...formData, [key]: { ...formData[key], [object]: e } });
  };

  const resetFilters = (e: FormEvent) => {
    e.preventDefault();

    let initialForm = {};

    filters?.forEach((filter: Filter) => {
      if (
        filter?.[filterTypeField] === 'RANGE' ||
        filter?.[filterTypeField] === 'RANGE_SELECT' ||
        filter?.[filterTypeField] === 'PRICE'
      ) {
        initialForm = { ...initialForm, [filter.id]: { from: '', to: '' } };
      } else if (
        filter?.[filterTypeField] === 'SELECT_OPTIONS' ||
        filter?.[filterTypeField] === 'LOCATION' ||
        filter?.[filterTypeField] === 'STREET' ||
        filter?.[filterTypeField] === 'SPECIFIC_TYPE' ||
        filter?.[filterTypeField] === 'ZONE' ||
        filter?.[filterTypeField] === 'OFFER_TYPE'
      ) {
        initialForm = { ...initialForm, [filter.id]: [] };
      } else {
        initialForm = { ...initialForm, [filter.id]: null };
      }
    });

    setFormData(initialForm);
    setFiltersForm && setFiltersForm(null);
  };

  const submitFilters = (e: FormEvent) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    let currentObject: any[] = [
      {
        category: activeCategory,
      },
    ];
    filters?.forEach((filter: Filter) => {
      if (
        formData[filter.id] &&
        formData[filter.id] !== null &&
        formData[filter.id] !== ''
      ) {
        if (
          filter?.[filterTypeField] === 'RANGE' ||
          filter?.[filterTypeField] === 'PRICE'
        ) {
          if (
            formData[filter.id]?.from !== '' &&
            formData[filter.id]?.from !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from,
            };
          if (
            formData[filter.id]?.to !== '' &&
            formData[filter.id]?.to !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to,
            };
        }
        if (filter?.[filterTypeField] === 'RANGE_SELECT') {
          if (
            formData[filter.id]?.from?.value &&
            formData[filter.id]?.from?.value !== '' &&
            formData[filter.id]?.from?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from?.value,
            };
          if (
            formData[filter.id]?.to?.value &&
            formData[filter.id]?.to?.value !== '' &&
            formData[filter.id]?.to?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to?.value,
            };
        } else if (
          filter?.[filterTypeField] === 'COUNTY' ||
          filter?.[filterTypeField] === 'TOWN'
        ) {
          if (formData[filter.id]?.value)
            currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter?.[filterTypeField] === 'SELECT') {
          currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter?.[filterTypeField] === 'EMPLOYEE') {
          if (formData[filter.id]?.value?.value)
            currentObject[filter.id] = {
              id: formData[filter.id]?.value?.value,
              associated: formData[filter.id]?.associated,
            };
        } else if (
          filter?.[filterTypeField] === 'SELECT_OPTIONS' ||
          filter?.[filterTypeField] === 'LOCATION' ||
          filter?.[filterTypeField] === 'STREET' ||
          filter?.[filterTypeField] === 'SPECIFIC_TYPE' ||
          filter?.[filterTypeField] === 'ZONE' ||
          filter?.[filterTypeField] === 'OFFER_TYPE'
        ) {
          if (formData[filter.id]?.length > 0)
            formData[filter.id].forEach((value: number) => {
              currentObject[filter.id] =
                currentObject[filter.id]?.length > 0
                  ? [...currentObject[filter.id], value]
                  : [value];
            });
        } else {
          currentObject[filter.id] = formData[filter.id];
        }
      }
    });

    currentObject = currentObject.map(item => {
      if (typeof item === 'object' && item !== null) {
        const hasNonEmptyValue = Object.values(item).some(
          value => value !== '' && value !== null,
        );
        return hasNonEmptyValue ? item : null;
      }
      return item;
    });

    currentObject?.length > 0 &&
      setFiltersForm &&
      setFiltersForm(currentObject);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const filterByGroup = (filter: Filter, position: Number) => {
    return filter.adGroup === position;
  };

  const renderContent = (filter: Filter) => {
    switch (filter?.[filterTypeField]) {
      case 'SELECT_OPTIONS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'OFFER_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={
              pricesTypes &&
              parseValues(pricesTypes).map(option => {
                return {
                  value: option.value,
                  label:
                    option.label === 'SALE'
                      ? 'Vanzare'
                      : option.label === 'RENT_MONTH'
                        ? 'Inchiriere'
                        : 'Regim hotelier',
                };
              })
            }
          />
        );
      case 'SELECT':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'LOCATION':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={locations && parseValues(locations)}
          />
        );
      case 'COUNTY':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setCountyId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={county && parseValues(county)}
          />
        );
      case 'TOWN':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setTownId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={localities && parseValues(localities)}
          />
        );
      case 'STREET':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => {
              handleChange(filter.id, e);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={streets && parseValues(streets)}
          />
        );
      case 'ZONE':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            isClearable={true}
            options={zona && parseValues(zona)}
          />
        );
      case 'SELECT_OPTIONS_ROOMS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            isClearable={true}
            options={roomsData}
          />
        );
      case 'SPECIFIC_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={specificTypes && parseValues(specificTypes)}
          />
        );
      case 'EMPLOYEE':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formData?.[filter.id]?.value ?? ''}
              onChange={(e: SelectValue) =>
                handleChangeRange(filter.id, 'value', e)
              }
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={users && parseValues(users)}
            />
            <Form.Group id='formGridCheckbox' key={filter.id}>
              <Form.Check
                type='checkbox'
                label={'Agent asociat'}
                checked={
                  formData?.[filter.id]?.associated === 1 ||
                  formData?.[filter.id]?.associated === true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'associated', e.target.checked)
                }
              />
            </Form.Group>
          </div>
        );
      case 'CONTACT_DATA_SELECT':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formData?.[filter.id] ?? ''}
              onChange={(e: SelectValue) => handleChange(filter.id, e)}
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={contactData}
            />
          </div>
        );
      case 'INPUT':
      case 'OWNER_PHONE':
      case 'ID':
      case 'STREET_NUMBER':
      case 'KEYWORDS':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                value={formData?.[filter.id] || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(filter.id, e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE':
      case 'PRICE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={formData?.[filter.id]?.from || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'from', e.target.value)
                }
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={formData?.[filter.id]?.to || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'to', e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_SELECT':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.from}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'from', e)
                }
              />
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.to}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'to', e)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_DATE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input range-date date-column'>
              {showCalendar && (
                <div className='range-calendar' ref={myRef}>
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: formData?.[filter.id]?.from
                          ? new Date(formData?.[filter.id]?.from)
                          : undefined,
                        endDate: formData?.[filter.id]?.to
                          ? new Date(formData?.[filter.id]?.to)
                          : undefined,
                        key: 'selection',
                      },
                    ]}
                    locale={locale.enUS}
                    onChange={e => {
                      handleSelect(filter.id, e);
                    }}
                  />
                </div>
              )}
              <ReactDatePicker
                placeholderText={
                  formData?.[filter.id]?.from && formData?.[filter.id]?.to
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : 'De la/Pana la'
                }
                value={
                  formData?.[filter.id]?.from &&
                  formData?.[filter.id]?.to &&
                  !showCalendar
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : ''
                }
                selected={null}
                onCalendarOpen={() => setShowCalendar(true)}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      case 'CHECKBOX':
      case 'VIRTUAL_TUR_CHECKBOX':
      case 'VIDEO_CHECKBOX':
      case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
      case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
      case 'HAS_CONTRACT_DATA_CHECKBOX':
      case 'EXCLUSIVENESS_CHECKBOX':
        return (
          <Form.Group id='formGridCheckbox' key={filter.id}>
            <Form.Check
              type='checkbox'
              label={filter.title}
              checked={
                formData?.[filter.id] === 1 || formData?.[filter.id] === true
                  ? formData?.[filter.id]
                  : false
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(filter.id, e.target.checked)
              }
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`${isMobile ? '' : 'filter-form-section'} ${openedFilters ? '' : 'closed-filters'}`}
    >
      <div className='filter-form-container property-filters-container '>
        {!isMobile && (
          <>
            <div className='filter-item-top'>
              <div className='filter-item'>
                <FormSelect
                  key='category-select'
                  labelText={true}
                  placeholder='Select Options'
                  inlineLabel='Tip Proprietate'
                  isSearchable={true}
                  value={
                    activeCategory
                      ? parseValues(categories)?.find(
                          option => option.value === String(activeCategory),
                        )
                      : { value: 0, label: 'Toate' }
                  }
                  options={parseValues(categories) ?? []}
                  onChange={(e: SelectValue) =>
                    setActiveCategory && setActiveCategory(Number(e.value))
                  }
                />
              </div>
            </div>
            <div className='header-section display-flex'>
              <div className='btns-section'>
                <button
                  className='save-btn btn-squere-blue'
                  aria-label='Salveaza'
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    setOpenedFilters && setOpenedFilters(!openedFilters);
                  }}
                >
                  <IconArrowUp className={openedFilters ? 'inverse' : ''} />
                </button>
              </div>
            </div>
          </>
        )}
        {isMobile && (
          <>
            <div className='header-section display-flex'>
              <h5 className='title-section'>
                Filtru{' '}
                {
                  categories.find(category => category.id === activeCategory)
                    ?.title
                }
              </h5>
            </div>
            <div className='select-propreties'>
              <FormSelect
                value={
                  activeCategory
                    ? parseValues(categories)?.find(
                        option => option.value === String(activeCategory),
                      )
                    : { value: 0, label: 'Toate' }
                }
                onChange={(e: SelectValue) => {
                  setActiveCategory && setActiveCategory(Number(e.value));
                }}
                labelText={false}
                options={[
                  { value: 0, label: 'Toate' },
                  ...parseValues(categories),
                ]}
              />
            </div>
          </>
        )}
        {loadingFilters ? (
          <Spinner />
        ) : (
          <form>
            <div
              className={`flex-rows ${openedFilters ? '' : 'short-filters'}`}
            >
              {filters && filters.length > 0 && (
                <>
                  {!openedFilters &&
                    filters
                      .filter(filter => filterByGroup(filter, 1))
                      .slice(0, openedFilters ? filters.length : 5)
                      .map((filter: Filter) => renderContent(filter))}
                  {openedFilters && (
                    <>
                      {filters.filter(filter => filterByGroup(filter, 1))
                        .length > 0 && (
                        <div className='filters-column'>
                          {filters
                            .filter(filter => filterByGroup(filter, 1))
                            .map((filter: Filter) => renderContent(filter))}
                        </div>
                      )}
                      {filters.filter(filter => filterByGroup(filter, 2))
                        .length > 0 && (
                        <div className='filters-column'>
                          {filters
                            .filter(filter => filterByGroup(filter, 2))
                            .map((filter: Filter) => renderContent(filter))}
                        </div>
                      )}
                      {filters.filter(filter => filterByGroup(filter, 3))
                        .length > 0 && (
                        <div className='filters-column'>
                          {filters
                            .filter(filter => filterByGroup(filter, 3))
                            .map((filter: Filter) => renderContent(filter))}
                        </div>
                      )}
                      {filters.filter(filter => filterByGroup(filter, 4))
                        .length > 0 && (
                        <div className='filters-column'>
                          {filters
                            .filter(filter => filterByGroup(filter, 4))
                            .map((filter: Filter) => renderContent(filter))}
                        </div>
                      )}
                      {filters.filter(filter => filterByGroup(filter, 5))
                        .length > 0 && (
                        <div className='filters-column'>
                          {filters
                            .filter(filter => filterByGroup(filter, 5))
                            .map((filter: Filter) => renderContent(filter))}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className='bottom-filter-section'>
              <div className='form-line-section'></div>
              <div className='form-btns-section'>
                <button className='reset-btn' onClick={e => resetFilters(e)}>
                  Reseteaza{' '}
                </button>
                <button
                  className='filter-btn btn-blue'
                  onClick={e => submitFilters(e)}
                >
                  {loading && <Spinner className='btn-blue-spinner' />}
                  Filtreaza
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default FilterListMap;
