import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { saveSlots } from '../../redux/partners/partnersThunk';
import useHelper from '../../helpers/useHelper';
import { Partner } from '../../models/Partner';
import Input from '../Input';
import Spinner from '../Spinner';
import './SlotAllocationTable.scss';
import { toast } from 'react-toastify';

interface Props {
  selectedPartner: Partner | null;
}

export interface FormItem {
  employeeId: number;
  packageId: number;
  value: number;
}

const SlotAllocationTable: React.FC<Props> = ({ selectedPartner }) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { slots, slotsLoading } = useAppSelector(state => state.partners);
  const [form, setForm] = useState<FormItem[]>([]);

  const setValue = (value: number, userId: number, packageId: number) => {
    const existentObject = form.filter(
      (formItem: FormItem) =>
        formItem.employeeId === userId && formItem.packageId === packageId,
    )[0];
    if (existentObject) {
      const newArray = form.map((formItem: FormItem) => {
        if (
          formItem.employeeId === userId &&
          formItem.packageId === packageId
        ) {
          return {
            ...formItem,
            value: value,
          };
        } else return formItem;
      });

      setForm(newArray);
    } else {
      setForm([
        ...form,
        {
          employeeId: userId,
          packageId: packageId,
          value: value,
        },
      ]);
    }
  };

  const getValue = (userId: number, packageId: number): number => {
    let value = 0;

    const existentObject = form.filter(
      (formItem: FormItem) =>
        formItem.employeeId === userId && formItem.packageId === packageId,
    )[0];

    if (existentObject) value = existentObject.value;
    else {
      slots?.userSlots.forEach(slot => {
        if (slot.userId === userId) {
          slot.partnerPackages.forEach(pkg => {
            if (pkg.id === packageId) value = pkg.value;
          });
        }
      });
    }

    return value;
  };

  const getTotals = (packageId: number) => {
    let total = 0;

    if (form.filter(formItem => formItem.packageId === packageId)?.length > 0) {
      slots?.userSlots.forEach(user => {
        const existentUser = form.filter(
          formItem =>
            formItem.packageId === packageId &&
            formItem.employeeId === user.userId,
        )[0];
        if (existentUser) total = total + existentUser.value;
        else {
          total =
            total +
            user.partnerPackages.filter(pkg => pkg.id === packageId)[0]?.value;
        }
      });
    } else {
      slots?.total.forEach(totalItem => {
        if (totalItem.partnerPackageId === packageId)
          total = totalItem.used ?? 0;
      });
    }

    return total;
  };

  const handleSaveSlots = async () => {
    if (selectedPartner) {
      try {
        const response = await dispatch(
          saveSlots({ id: selectedPartner?.id, data: form }),
        );
        unwrapResult(response);

        if (response.type === 'crm/saveSlots/fulfilled')
          toast.success('Updated successfully');
      } catch (err: any) {
        diplayErrorMessages(err);
      }
    }
  };

  const resetForm = () => {
    setForm([]);
  };

  return (
    <div className='slot-allocation-wrapper'>
      {slotsLoading ? (
        <div className='container'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='slot-allocation-table'>
            <div className='slot-allocation-item-thead'>
              <div className='slot-allocation-item desktop-titles'>
                <div className='slot-column'>Agent</div>
                {selectedPartner?.packages?.map(pkg => (
                  <div className='slot-column'>
                    <span>{pkg.title}</span>
                  </div>
                ))}
              </div>
              <div className='slot-allocation-item-total'>
                <div className='slot-column-line'>
                  <div className='slot-column'>Total Alocate</div>

                  {slots?.total?.map(totalItem => (
                    <div className='column-item'>
                      <div className='mobile-text'>
                        {totalItem.partnerPackageTitle}
                      </div>
                      <div className='slot-column'>
                        <div className='content-numbers'>
                          <span className='pill'>
                            {getTotals(totalItem.partnerPackageId)}
                          </span>
                          {totalItem.value -
                            getTotals(totalItem.partnerPackageId)}{' '}
                          ramase
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {slots?.userSlots?.map((user, index) => (
              <div className='slot-allocation-item' key={'agent-' + index}>
                <div className='column-item'>
                  <div className='mobile-text'>Agent</div>
                  <div className='contact-details'>
                    <div className='info-contact-details'>{user?.fullName}</div>
                  </div>
                </div>
                {user.partnerPackages.map(pkg => (
                  <div className='column-item'>
                    <div className='mobile-text'>{pkg.title}</div>
                    <div className='slot-column'>
                      <div className='content-numbers'>
                        <Input
                          type='number'
                          value={getValue(user.userId, pkg.id)}
                          onChange={e =>
                            setValue(+e.target.value, user.userId, pkg.id)
                          }
                          classNameInput='pill'
                        />
                        <span>{pkg.used} folosite</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='btns-container-wrapper'>
            <div className='btns-container'>
              <button
                className='button-blue button-md'
                onClick={handleSaveSlots}
              >
                Salveaza
              </button>
              <button className='reset-btn' onClick={resetForm}>
                Reseteaza
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SlotAllocationTable;
