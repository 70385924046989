import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  getContract,
  previewContract,
} from '../../redux/contracts/contractsThunk';
import { getEvaluationPdf } from '../../redux/evaluations/evaluationsThunk';
import { useAppDispatch } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import {
  Contract,
  ContractFormData,
  ContractPreview,
} from '../../models/Contract';
import { EvaluationBody } from '../../models/Evaluation';
import IconPDF from '../../icons/IconPDF';
import IconPencilWriting from '../../icons/IconPencilWriting';
import IconTimes from '../../icons/IconTimes';
import Spinner from '../Spinner';
import './ModalPDF.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showModal: boolean;
  item?: Contract | ContractFormData | EvaluationBody;
  hideModal: () => void;
  isDownload?: boolean;
  url?: string;
}

const ModalPDF: React.FC<Props> = ({
  url,
  item,
  showModal,
  hideModal,
  isDownload,
}) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const [base64Data, setBase64Data] = useState('');
  const [loading, setLoading] = useState(true);

  const [isLaptop, setIsLaptop] = useState(window.innerHeight < 990);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsLaptop(window.innerHeight < 990);
  };

  useEffect(() => {
    if (url) {
      setLoading(false);
      setBase64Data(url);
      return
    }
    if (item) {
      if ('medianPrice' in item) {
        preview(item);
      }

      if ('id' in item) {
        getContractFunction(item);
      }

      if ('contractTemplateId' in item) {
        item?.contractTemplateId &&
          preview({
            contractTemplateId: item.contractTemplateId,
            data: item?.data
              ?.filter((item: any) => item.value?.length > 0)
              .map((item: any) => ({ slug: item.slug, value: item.value, type: item.type })),
          });
      }
    }
    // eslint-disable-next-line
  }, []);

  const getContractFunction = async (contract: Contract) => {
    try {
      const response = await dispatch(getContract(contract.id));
      unwrapResult(response);

      if(response?.payload?.fileCertificate?.path) {
        setBase64Data(`${process.env.REACT_APP_API_URL}${response?.payload?.fileCertificate?.path}`);
        setLoading(false);
        return;
      }

      preview({
        contractTemplateId: response?.payload?.contractTemplate?.id,
        data: response?.payload?.data
          ?.filter((item: any) => item.value?.length > 0)
          .map((item: any) => ({ slug: item.slug, value: item.value, type: item.type })),
      });
    } catch (err: any) {}
  };

  const preview = async (data: ContractPreview | EvaluationBody) => {
    try {
      let resultPreview;
      if ('contractTemplateId' in data) {
        resultPreview = await dispatch(previewContract(data));
      } else {
        resultPreview = await dispatch(getEvaluationPdf(data));
      }
      unwrapResult(resultPreview);

      const blob = resultPreview.payload.data;

      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setBase64Data(`${reader.result}#toolbar=0&navpanes=0&scrollbar=0`);
        } else {
          console.error('Error: FileReader result is not a string');
        }
      };
      reader.readAsDataURL(blob);
    } catch (err: any) {
      hideModal();
      diplayErrorMessages(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={hideModal}
        animation={false}
        className='doc-modal'
      >
        <button
          className='close-modal'
          aria-label='Închide'
          onClick={hideModal}
        >
          <IconTimes />
        </button>
        <Modal.Body>
          <div
            style={{
              width: '100%',
              height: isLaptop ? '550px' : '750px',
            }}
          >
            {loading ? (
              <Spinner />
            ) : base64Data ? (
              <object
                data={base64Data}
                type='application/pdf'
                width='100%'
                height={'100%'}
              >
                <p>
                  Your browser does not support inline PDFs.{' '}
                  <a href={base64Data} download='document.pdf'>
                    Download the PDF
                  </a>
                </p>
              </object>
            ) : (
              <p>No PDF yet.</p>
            )}
          </div>
        </Modal.Body>
        {!!item && (
          <>
            <Modal.Footer>
              {isDownload ? (
                base64Data ? (
                  <a
                    href={base64Data}
                    download='document.pdf'
                    className='button-blue button-md download-btn'
                  >
                    Descarca <IconPDF />
                  </a>
                ) : (
                  !loading && <p>No PDF yet.</p>
                )
              ) : (
                <button
                  className='button-blue button-md'
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Semneaza <IconPencilWriting />
                </button>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default ModalPDF;
