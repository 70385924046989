import React from 'react';
import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectValue } from '../../models/Properties';
import IconArrowLeft from '../../icons/IconArrowLeft';
import IconArrowRight from '../../icons/IconArrowRight';
import Actions from '../Actions';
import './FilterListHeader.scss';

const optionsMock = [
  {
    value: 'edit',
    label: 'Modifica',
  },
  {
    value: 'export',
    label: 'Exporta',
  },
  {
    value: 'remove',
    label: 'Sterge',
  },
];

interface Props {
  pageCount?: number;
  currentPage?: number;
  itemsPerPage?: number;
  totalCount?: number;
  count?: number;
  checkedItemsLength?: number;
  showCheckedItems?: boolean;
  setShowCheckedItems?: (e: boolean) => void;
  setItemsPerPage?: (e: number) => void;
  setCurrentPage?: (e: number) => void;
  handlePageClick?: (selectedItem: { selected: number }) => void;
  removeItems?: () => void;
  editItems?: () => void;
  restoreItems?: () => void;
  arhiveItems?: () => void;
  exportItems?: () => void;
  exportPdfItems?: () => void;
  exportXlsxItems?: () => void;
  printItems?: () => void;
  loading?: boolean;
  options?: SelectValue[];
  showActions?: any;
  fromDB?: boolean;
}

const FilterListHeader: React.FC<Props> = ({
  pageCount,
  currentPage,
  itemsPerPage,
  totalCount,
  count,
  options = optionsMock,
  checkedItemsLength,
  showCheckedItems,
  loading,
  showActions = true,
  setShowCheckedItems,
  setItemsPerPage,
  handlePageClick,
  removeItems,
  editItems,
  exportItems,
  printItems,
  arhiveItems,
  setCurrentPage,
  restoreItems,
  exportPdfItems,
  exportXlsxItems,
  fromDB = true,
}) => {
  const location = useLocation();
  const eventHandler = (e: SelectValue) => {
    if (e.value === 'remove') {
      removeItems && removeItems();
    }
    if (e.value === 'edit') {
      editItems && editItems();
    }

    if (e.value === 'export-pdf') {
      exportPdfItems && exportPdfItems();
    }
    if (e.value === 'export-xlsx') {
      exportXlsxItems && exportXlsxItems();
    }

    if (e.value === 'export') {
      exportItems
        ? exportItems()
        : toast.error('Acest functional temporar nu exista!');
    }
    if (e.value === 'print') {
      printItems
        ? printItems()
        : toast.error('Acest functional temporar nu exista!');
    }
    if (e.value === 'arhive') {
      arhiveItems && arhiveItems();
    }
    if (e.value === 'restore') {
      restoreItems && restoreItems();
    }
  };

  const getEntitiesName: () => string = () => {
    if (location.pathname.includes('/tag-ads/')) return 'Anunturi';

    switch (location.pathname) {
      case '/my-contacts':
      case '/agency-contacts':
        return 'Contacte';
      case '/my-properties':
      case '/agency-properties':
        return 'Proprietati';
      case '/my-activities':
        return 'Activitati';
      case '/residential-complexes':
        return 'Ansambluri rezidentiale';
      case '/my-requests':
      case '/company-requests':
        return 'Solicitari';
      case '/ads':
        return 'Anunturi';
      case '/ads-tags':
        return 'Etichete';
      case '/contract-templates':
        return 'Sabloane';
      case '/price-evaluation':
        return 'Evaluari';
      default:
        return 'Proprietati';
    }
  };

  return (
    <div className='filter-list-header-container'>
      <div className='filter-list-header'>
        <div className='tabs-container'>
          <div
            className={
              !showCheckedItems
                ? 'all-list tab-item active'
                : 'all-list tab-item '
            }
            onClick={() => setShowCheckedItems && setShowCheckedItems(false)}
          >
            <div className='tab-icon'>
              <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                viewBox='0 0 251 93'
                width={251}
                height={93}
              >
                <path d='M174.2,0h-154C9,0,0,9,0,20.1V93h239.4l-47.7-82.9C188.1,3.9,181.4,0,174.2,0z' />
                <path d='M251,93h-1.3C250.5,93,251,93,251,93z' />
                <path d='M233.3,82.4l6.1,10.5h10.4C246.7,92.8,238.7,91.5,233.3,82.4z' />
              </svg>
            </div>
            <p>
              Toate<span> ({totalCount})</span>
            </p>
          </div>
          {showActions && (
            <div
              className={`selected-list tab-item ${showCheckedItems ? 'active' : ''}`}
              onClick={() => setShowCheckedItems && setShowCheckedItems(true)}
            >
              <div className='tab-icon'>
                <svg
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                  x='0px'
                  y='0px'
                  viewBox='0 0 271 93'
                  width={271}
                  height={93}
                >
                  <g>
                    <path d='M194.2,0h-154C29,0,20,9,20,20.1v53.1C18.9,93,0,93,0,93h20h239.3l-47.7-82.9C208.1,3.9,201.4,0,194.2,0z' />
                    <path d='M269.7,93c0.8,0,1.3,0,1.3,0H269.7z' />
                    <path d='M253.3,82.4l6.1,10.5h10.4C266.7,92.8,258.7,91.5,253.3,82.4z' />
                  </g>
                </svg>
              </div>
              <p>
                Selectate<span> ({checkedItemsLength})</span>
              </p>
            </div>
          )}
        </div>
        {(showActions || (!showActions && !showCheckedItems)) &&
          fromDB &&
          count !== 0 && (
            <div className='pagination-list-container'>
              {showActions ? (
                <div className='action-select'>
                  <Actions
                    labelText={false}
                    placeholder='Actiuni'
                    options={showActions && options}
                    getValue={eventHandler}
                  />
                </div>
              ) : null}
              <div className='list-info-text'>
                {count && count > 0
                  ? `${showCheckedItems ? checkedItemsLength : count} ${getEntitiesName()} din ${totalCount} ${pageCount && (pageCount > 1 ? ' / ' + pageCount + ' pagini' : '/ 1 pagina')}`
                  : null}
              </div>
              {!showCheckedItems &&
              pageCount &&
              currentPage !== undefined &&
              itemsPerPage ? (
                <>
                  <div className='page-number-container'>
                    <Form.Group controlId='formGridState'>
                      <Form.Select
                        defaultValue={itemsPerPage}
                        onChange={e => {
                          setItemsPerPage &&
                            setItemsPerPage(Number(e.target.value));
                          setCurrentPage && setCurrentPage(0);
                        }}
                      >
                        <option value={24}>24</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {pageCount !== 0 && (
                    <div
                      className={`pagination-container ${loading ? 'disabled' : ''}`}
                    >
                      <div className='list-filter-pagination'>
                        <ReactPaginate
                          breakLabel='...'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={2}
                          marginPagesDisplayed={1}
                          pageCount={pageCount}
                          renderOnZeroPageCount={null}
                          forcePage={currentPage}
                          nextLabel={<IconArrowRight />}
                          previousLabel={<IconArrowLeft />}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          )}
      </div>
    </div>
  );
};

export default FilterListHeader;
