import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import ModalPDF from '../../components/ModalPDF';
import { removeEvaluation } from '../../redux/evaluations/evaluationsThunk';
import { useAppDispatch } from '../../redux/hooks';
import { EvaluationListItem } from '../../models/Evaluation';
import IconPDF from '../../icons/IconPDF';
import IconPencil from '../../icons/IconPencil';
import IconTrash from '../../icons/IconTrash';
import ModalConfirm from '../ModalConfirm';
import './PriceEvaluationListItem.scss';

interface Props {
  item: EvaluationListItem;
  getEvaluationsList: () => void;
}

function formatDataForDisplay(item: EvaluationListItem) {
  const labels: string[] = [];

  item.filters.forEach(filter => {
    switch (filter.type) {
      case 'OFFER_TYPE':
        labels.push(
          filter.value?.includes('1')
            ? 'Vanzare'
            : filter.value?.includes('2')
              ? 'Inchiriere'
              : 'Regim hotelier',
        );
        break;
      case 'COUNTY':
        labels.push(`Judet: ${filter.valueTitle}`);
        break;
      case 'TOWN':
        labels.push(`Oras: ${filter.valueTitle}`);
        break;
      case 'ZONE':
        labels.push(
          `Zone: ${
            typeof filter.valueTitle !== 'string' &&
            filter.valueTitle?.join(', ')
          }`,
        );
        break;
      case 'RANGE_SELECT':
      case 'RANGE':
        {
          const from = filter.value.from || '';
          const to = filter.value.to || '';
          if (from && to) {
            labels.push(
              `${filter.title}: ${from}${to && from ? '-' : ''}${to}`,
            );
          }
        }
        break;
      case 'SELECT_OPTIONS_ROOMS':
        filter.value.forEach((value: string) => {
          switch (value) {
            case '1':
              labels.push(`Garsoniera`);
              break;
            case '2':
              labels.push(`2 camere`);
              break;
            case '3':
              labels.push(`3 camere`);
              break;
            case '4':
              labels.push(`4 camere`);
              break;
            case '5':
              labels.push(`5+ camere`);
          }
        });
        break;
      case 'CHECKBOX':
      case 'VIRTUAL_TUR_CHECKBOX':
      case 'VIDEO_CHECKBOX':
      case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
      case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
      case 'HAS_CONTRACT_DATA_CHECKBOX':
      case 'EXCLUSIVENESS_CHECKBOX':
        labels.push(`${filter.title}`);
        break;
      default:
        labels.push(filter.value);
    }
  });

  return labels.map((label: string) => (
    <span className='label-item-tag'>{label}</span>
  ));
}

const PriceEvaluationListItem: React.FC<Props> = ({
  item,
  getEvaluationsList,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const removeItem = async () => {
    try {
      const result = await dispatch(removeEvaluation(item.id));
      unwrapResult(result);

      toast.success('Evaluarea a fost stearsa!');
      getEvaluationsList();
    } catch (err: any) {}
  };

  return (
    <div className={`announces-list-item item-price-evaluation`}>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>ID</div>
          <div className='info-nr label-item-mobile'>{item.id}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Data adaugare</div>
          <div className='info-date label-item-mobile'>
            {moment(item.dateCreated).format('DD.MM.YYYY')}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Filtre</div>
          <div className='info-list display-flex flex-wrap'>
            {formatDataForDisplay(item)}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Anunturi analizate</div>
          <div className=' label-item-mobile'>{item.processedAnnounces}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Pret recomandat</div>
          <div className='info-price label-item-mobile'>
            <span className='label-item-tag'>€ {item.medianPrice}</span>
          </div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            onClick={() => setShowConfirmModal(true)}
            data-tooltip-id='pdf'
            data-tooltip-content='PDF'
          >
            <IconPDF />
          </button>
          <Tooltip id='pdf' />
          {showConfirmModal && (
            <ModalPDF
              showModal={showConfirmModal}
              item={item}
              hideModal={() => setShowConfirmModal(false)}
              isDownload
            />
          )}

          <button
            className='button-square-md'
            aria-label=''
            onClick={() => navigate('/edit-evaluation/' + item.id)}
          >
            <IconPencil />
          </button>
          <button
            className='button-square-md'
            data-tooltip-id='remove'
            data-tooltip-content='Sterge'
            aria-label='Sterge'
            onClick={e => {
              e.preventDefault();
              setShowDeleteModal(true);
            }}
          >
            <IconTrash />
          </button>
          <Tooltip id='remove' />
        </div>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere evaluare'
        body='Esti sigur ca doresti sa stergi evaluarea?'
        onConfirmed={() => removeItem()}
        hideModal={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default PriceEvaluationListItem;
