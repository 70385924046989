import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormSelect from '../../components/FormSelect';
import Header from '../../components/Header';
import Input from '../../components/Input';
import MissingAccess from '../../components/MissingAccess';
import ModalPDF from '../../components/ModalPDF';
import ModalScanContract from '../../components/ModalScanContract';
import ModalSign from '../../components/ModalSign';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { getCitizenships } from '../../redux/citizenships/citizenshipsThunk';
import {
  clearContract,
  clearContractTemplate,
} from '../../redux/contracts/contracts-slice';
import {
  createContract,
  getContract,
  getContractTemplate,
  getContractTemplateFields,
  getContractsTemplates,
  updateContract,
} from '../../redux/contracts/contractsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPricesTypes } from '../../redux/properties/propertiesThunk';
import useHelper, {
  getFilePath,
  getValue,
  haveUserPermission,
  parseValues,
} from '../../helpers/useHelper';
import {
  ContractTemplateField,
  DataFieldItem,
  PrefillData,
  PrefillItem,
} from '../../models/Contract';
import { Permissions } from '../../models/Permissions';
import { SelectValue } from '../../models/Properties';
import Avatar from '../../assets/img/avatar.png';
import IconBarcode from '../../icons/IconBarcode';
import IconPencilWriting from '../../icons/IconPencilWriting';
import './AddContract.scss';

interface Props {}

function prefillFields(
  fields: PrefillItem[],
  data: PrefillData,
): PrefillItem[] {
  const mapping: Record<string, keyof PrefillData> = {
    PREFILLED_FIRST_NAME: 'firstName',
    PREFILLED_LAST_NAME: 'lastName',
    PREFILLED_CNP: 'cnp',
    PREFILLED_CI_SERIES: 'series',
    PREFILLED_CI_NUMBER: 'seriesNumber',
    PREFILLED_ADDRESS: 'address',
  };

  return fields.map(field => {
    const key = mapping[field.type];
    if (key && data[key] !== undefined) {
      return {
        ...field,
        value: data[key],
      };
    }
    return field;
  });
}

const AddContract: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();
  const { id } = useParams();

  const {
    contractsTemplates,
    contract,
    contractTemplate,
    contractTemplateFields,
    signErrors,
  } = useAppSelector(state => state.contracts);
  const { auth } = useAppSelector(state => state.auth);
  const { categories } = useAppSelector(state => state.categories);
  const { pricesTypes } = useAppSelector(state => state.properties);
  const { citizenships } = useAppSelector(state => state.citizenships);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showScanModal, setShowScanModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState<number | null>(null);
  const [loading, setLoading] = useState(id ? true : false);
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    employeeEmail: auth?.email ?? '',
    agencyEmail: auth?.profile?.organization?.email ?? '',
    data: [],
  });
  const [searchParams] = useSearchParams();
  const isSignTrue = searchParams.get('sign') === 'true';
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AddContract),
  );
  useEffect(() => {
    if (isSignTrue && id) {
      setShowSignModal(+id);
    }
  }, [isSignTrue, id]);

  useEffect(() => {
    dispatch(clearContract());
    dispatch(getCitizenships({ limit: -1, sort: 'title' }));
    dispatch(getContractsTemplates({}));
    dispatch(getContractTemplateFields({}));
    dispatch(getCategories());
    dispatch(getPricesTypes());
    setFormData({
      employeeEmail: auth?.email ?? '',
      agencyEmail: auth?.profile?.organization?.email ?? '',
      data: [],
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      handleGetContract();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    let newArray: any[] = [];
    [...contractTemplateFields, ...(contractTemplate?.fields ?? [])].forEach(
      (field: any) => {
        const fromContract = contract?.data?.filter(
          (item: any) => item.id === field.id,
        )[0];
        let value = '';
        if (field.type === 'PREFILLED_AGENCY_EMPLOYEE_NAME')
          value = auth?.profile?.fullName?.split(' ')[0] ?? '';
        if (field.type === 'PREFILLED_AGENCY_EMPLOYEE_SURNAME')
          value = auth?.profile?.fullName?.split(' ')[1] ?? '';
        if (field.type === 'PREFILLED_AGENCY_NAME')
          value = auth?.profile?.organization?.name ?? '';

        fromContract
          ? newArray.push(fromContract)
          : newArray.push({
              slug: field.slug,
              value: value,
              type: field.type ?? 'INPUT',
              id: field.id,
              valueId: null,
            });
      },
    );

    setFormData({
      ...formData,
      data: newArray,
    });
    // eslint-disable-next-line
  }, [contractTemplateFields, contractTemplate]);

  useEffect(() => {
    if (formData.contractTemplateId) {
      dispatch(getContractTemplate(formData.contractTemplateId));
    } else {
      dispatch(clearContractTemplate());
    }
    // eslint-disable-next-line
  }, [formData.contractTemplateId]);

  useEffect(() => {
    setFormDataErrors(signErrors);
  }, [signErrors]);

  const handleGetContract = async () => {
    try {
      const response = await dispatch(getContract(id));
      unwrapResult(response);

      setTimeout(() => setLoading(false), 500);

      let newArray: any[] = [];
      [...contractTemplateFields, ...(contractTemplate?.fields ?? [])].forEach(
        (field: any) => {
          const fromContract = response?.payload?.data?.filter(
            (item: any) => item.id === field.id,
          )[0];
          let value = '';
          if (field.type === 'PREFILLED_AGENCY_EMPLOYEE_NAME')
            value = auth?.profile?.fullName?.split(' ')[0] ?? '';
          if (field.type === 'PREFILLED_AGENCY_EMPLOYEE_SURNAME')
            value = auth?.profile?.fullName?.split(' ')[1] ?? '';
          if (field.type === 'PREFILLED_AGENCY_NAME')
            value = auth?.profile?.organization?.name ?? '';

          fromContract
            ? newArray.push(fromContract)
            : newArray.push({
                slug: field.slug,
                value: value,
                type: field.type ?? 'INPUT',
                id: field.id,
                valueId: null,
              });
        },
      );

      setFormData({
        ...formData,
        employeeEmail: response?.payload?.email ?? auth?.email ?? '',
        clientEmail: response?.payload?.clientEmail ?? '',
        agencyEmail:
          response?.payload?.agencyEmail ??
          auth?.profile?.organization?.email ??
          '',
        contractTemplateId:
          response?.payload?.contractTemplate?.id ??
          formData.contractTemplateId ??
          null,
        title: response?.payload?.title ?? '',
        signStatus: response?.payload?.signStatus ?? '',
        data: newArray,
        signedUrl: response?.payload?.fileCertificate?.path ? `${process.env.REACT_APP_API_URL}${response?.payload?.fileCertificate?.path}` : '',
      });
    } catch (err) {
      diplayErrorMessages(err);
    }
  };

  const getValueData = (id: number) => {
    let value: DataFieldItem | null = { value: '' };

    formData.data.forEach((item: DataFieldItem) => {
      if (item?.id === id) value = item;
    });

    return value;
  };

  const setValueData = (
    id: number,
    value: string,
    type: string,
    valueId?: number | string,
  ) => {
    let values = formData.data;

    setFormData({
      ...formData,
      data: values.map((item: DataFieldItem) => {
        if (item?.id === id)
          return {
            ...item,
            ...(type === 'PROPERTY_TYPE' ||
            type === 'TRANSACTION' ||
            type === 'CITIZENSHIP'
              ? { value: value, valueId: valueId }
              : { value: value }),
          };
        else return item;
      }),
    });
  };

  const getField = (field: ContractTemplateField) => {
    switch (field.type) {
      case 'INPUT':
        return (
          <Input
            labelText={true}
            inlineLabel={field.title}
            error={
              formDataErrors?.data?.[field.id] || formDataErrors?.[field.type]
            }
            disabled={
              (contract && contract?.signStatus === 'completed') ?? false
            }
            type={'text'}
            value={getValueData(field.id)?.value ?? ''}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValueData(field.id, e.target.value, 'INPUT')
            }
          />
        );
      case 'PROPERTY_TYPE':
        return (
          <div className='form-item'>
            <FormSelect
              placeholder='Select Options'
              labelText={true}
              inlineLabel={field.title}
              error={
                formDataErrors?.data?.[field.id] || formDataErrors?.[field.type]
              }
              isDisabled={contract && contract?.signStatus === 'completed'}
              options={parseValues(categories)}
              value={
                parseValues(categories).filter(
                  item => item.label === getValueData(field.id)?.value,
                )[0] ?? ''
              }
              onChange={(e: SelectValue) =>
                setValueData(
                  field.id,
                  parseValues(categories).filter(
                    item => item.value === e.value,
                  )[0]?.label,
                  'PROPERTY_TYPE',
                  Number(e.value),
                )
              }
            />
          </div>
        );
      case 'TRANSACTION':
        return (
          <div className='form-item'>
            <FormSelect
              placeholder='Select Options'
              labelText={true}
              inlineLabel={field.title}
              error={
                formDataErrors?.data?.[field.id] || formDataErrors?.[field.type]
              }
              isDisabled={contract && contract?.signStatus === 'completed'}
              options={
                pricesTypes &&
                parseValues(pricesTypes).map(option => {
                  return {
                    value: option.value,
                    label:
                      option.label === 'SALE'
                        ? 'Vanzare'
                        : option.label === 'RENT_MONTH'
                          ? 'Inchiriere'
                          : 'Regim hotelier',
                  };
                })
              }
              value={
                parseValues(pricesTypes)
                  .filter(item => item.label === getValueData(field.id)?.value)
                  .map(option => {
                    return {
                      value: option.value,
                      label:
                        option.label === 'SALE'
                          ? 'Vanzare'
                          : option.label === 'RENT_MONTH'
                            ? 'Inchiriere'
                            : 'Regim hotelier',
                    };
                  })[0] ?? ''
              }
              onChange={(e: SelectValue) =>
                setValueData(
                  field.id,
                  parseValues(pricesTypes).filter(
                    item => item.value === e.value,
                  )[0]?.label,
                  'TRANSACTION',
                  Number(e.value),
                )
              }
            />
          </div>
        );
      case 'CITIZENSHIP':
      case 'PREFILLED_CITIZENSHIP':
        return (
          <div className='form-item'>
            <FormSelect
              placeholder='Select Options'
              labelText={true}
              inlineLabel={field.title}
              error={
                formDataErrors?.data?.[field.id] || formDataErrors?.[field.type]
              }
              isDisabled={contract && contract?.signStatus === 'completed'}
              options={citizenships && parseValues(citizenships)}
              value={
                parseValues(citizenships).filter(
                  item => item.label === getValueData(field.id)?.value,
                )[0] ?? ''
              }
              onChange={(e: SelectValue) =>
                setValueData(
                  field.id,
                  parseValues(citizenships).filter(
                    item => item.value === e.value,
                  )[0]?.label,
                  'CITIZENSHIP',
                  Number(e.value),
                )
              }
            />
          </div>
        );
      case 'DATE':
        return (
          <div className='form-group filter-item'>
            <label className='form-item-group-label'>{field.title}</label>
            <div className='form-item'>
              <div className='date-picker'>
                <DatePicker
                  disabled={
                    (contract && contract?.signStatus === 'completed') ?? false
                  }
                  selected={
                    getValueData(field.id)?.value
                      ? new Date(getValueData(field.id)?.value)
                      : null
                  }
                  onChange={(e: any) =>
                    setValueData(field.id, String(e), 'DATE')
                  }
                />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <Input
            labelText={true}
            inlineLabel={field.title}
            error={
              formDataErrors?.data?.[field.id] || formDataErrors?.[field.type]
            }
            disabled={
              (contract && contract?.signStatus === 'completed') ?? false
            }
            type={'text'}
            value={getValueData(field.id)?.value ?? ''}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValueData(field.id, e.target.value, 'INPUT')
            }
          />
        );
    }
  };
  const filterByGroup = (array: any, additional = false) => {
    let groups: any[] = additional ? ['Aditionale'] : [];

    if (!additional) {
      array.forEach((field: any) => {
        if (!groups?.includes(field.group)) groups.push(field.group);
      });
    }

    return groups?.map((group: any, index) => {
      return (
        <Row key={index}>
          <Col md={12}>
            <h2 className='fieldset-heading'>
              {group}
              {index === 0 &&
                !additional &&
                (!contract || contract?.signStatus !== 'completed') && (
                  <button
                    className='link'
                    onClick={e => {
                      e.preventDefault();
                      setShowScanModal(true);
                    }}
                  >
                    <span className='link-label'>Scaneaza</span>
                    <span className='button-square-md btn-main'>
                      <IconBarcode />
                    </span>
                  </button>
                )}
            </h2>
          </Col>
          {array
            .filter((field: ContractTemplateField) =>
              additional ? field : field.group === group,
            )
            .map((field: ContractTemplateField) => (
              <Col md={4} key={field.id + 'field'}>
                {getField(field)}{' '}
              </Col>
            ))}
        </Row>
      );
    });
  };

  const submit = async () => {
    if (formData) {
      const data = {
        ...formData,
        data: formData?.data.filter((item: any) => item.value?.length > 0),
      };

      try {
        const response = await dispatch(
          id
            ? updateContract({
                id: id,
                data: data,
              })
            : createContract(data),
        );
        unwrapResult(response);

        if (response?.payload?.id) {
          toast.success('Contractul a fost salvat cu success!');
          navigate('/contracts');
        }
      } catch (err: any) {
        setFormDataErrors(err);
        diplayErrorMessages(err);
      }
    }
  };

  const signContract = async () => {
    setFormDataErrors({});

    if (formData) {
      const data = {
        ...formData,
        data: formData?.data.filter((item: any) => item.value?.length > 0),
      };

      try {
        const response = await dispatch(
          id
            ? updateContract({
                id: id,
                data: data,
              })
            : createContract(data),
        );
        unwrapResult(response);

        if (response?.payload?.id) {
          id
            ? setShowSignModal(+id)
            : navigate(
                '/edit-contract/' + response?.payload?.id + '?sign=true',
              );
        }
      } catch (err: any) {
        setFormDataErrors(err);
        diplayErrorMessages(err);
      }
    }
  };

  const handlePrefillData = (data: any) => {
    const newPrefilledData = prefillFields(formData?.data, data);

    setFormData({ ...formData, data: newPrefilledData });
  };

  return (
    <>
      <div className='flex-container display-flex'>
        <SideNav />
        <div className='display-flex flex-column main-wrapper'>
          <Header
            title={id ? 'Editare contract' : 'Creeaza contract'}
            subtitle=''
            isbutton={true}
            btnText='Add new contract'
            btnEvent={() => navigate('/add-contract')}
            breadcrumbs={[
              { link: '/', name: 'Home' },
              { link: '/contracts', name: 'Contracte' },
              {
                link: '/add-contract',
                name: id ? 'Editare contract' : 'Creeaza contract',
              },
            ]}
          />

          {havePermission ? (
            <div className='display-flex flex-wrap'>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <div className='ads-contracts-sm'>
                    <div
                      className={`ads-general-container ads-contracts-container bg-white `}
                    >
                      <div className='ads-contracts-info'>
                        <table>
                          <tbody>
                            <tr>
                              <th className='ads-contracts-info__heading'>
                                Adaugat la:
                              </th>
                              <td className='ads-contracts-info__content'>
                                {moment(new Date()).format('DD.MM.YYYY')}
                              </td>
                            </tr>
                            <tr>
                              <th className='ads-contracts-info__heading'>
                                Ultima modificare:
                              </th>
                              <td className='ads-contracts-info__content'>
                                {moment(new Date()).format('DD.MM.YYYY')}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='user-general-info'>
                        <div className='image-block'>
                          <img
                            src={
                              auth?.profile?.avatar?.path
                                ? getFilePath(auth?.profile?.avatar?.path)
                                : Avatar
                            }
                            alt=''
                          />
                        </div>

                        <div className='content-info'>
                          <p className='name'>{auth?.profile?.fullName}</p>
                          <span className='info'>
                            {auth?.profile?.phoneNumbers?.[0]}
                          </span>
                        </div>
                      </div>
                      <div className='action-block'>
                        <span className='label-gray'>
                          {formData?.signStatus === 'completed'
                            ? 'Semnat'
                            : 'In draft'}
                        </span>
                      </div>
                      <div className='ads-contracts-info'>
                        <table>
                          <tbody>
                            {formData?.data?.filter(
                              (item: any) => item.type === 'TRANSACTION',
                            )[0]?.valueId && (
                              <tr>
                                <th className='ads-contracts-info__heading'>
                                  Tip Tranzactie:
                                </th>
                                <td className='ads-contracts-info__content'>
                                  {
                                    parseValues(pricesTypes)
                                      .filter(
                                        item =>
                                          item.label ===
                                          formData?.data?.filter(
                                            (item: any) =>
                                              item.type === 'TRANSACTION',
                                          )[0]?.value,
                                      )
                                      .map(option => {
                                        return {
                                          value: option.value,
                                          label:
                                            option.label === 'SALE'
                                              ? 'Vanzare'
                                              : option.label === 'RENT_MONTH'
                                                ? 'Inchiriere'
                                                : 'Regim hotelier',
                                        };
                                      })[0]?.label
                                  }
                                </td>
                              </tr>
                            )}
                            {formData?.data?.filter(
                              (item: any) => item.type === 'PROPERTY_TYPE',
                            )[0]?.valueId && (
                              <tr>
                                <th className='ads-contracts-info__heading'>
                                  Tip Imobil:
                                </th>
                                <td className='ads-contracts-info__content'>
                                  {
                                    parseValues(categories).filter(
                                      item =>
                                        item.label ===
                                        formData?.data?.filter(
                                          (item: any) =>
                                            item.type === 'PROPERTY_TYPE',
                                        )[0]?.value,
                                    )[0]?.label
                                  }
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='ads-contracts-lg'>
                    <div className='bg-blue'>
                      <div className='ads-container bg-white'>
                        <form>
                          <Row className='fieldset-form'>
                            <Col md={6}>
                              <div className='filter-item'>
                                <FormSelect
                                  labelText={true}
                                  placeholder='Select Option'
                                  inlineLabel={'Tip contract'}
                                  isSearchable={true}
                                  isClearable={true}
                                  isDisabled={
                                    contract &&
                                    contract?.signStatus === 'completed'
                                  }
                                  error={formDataErrors?.contractTemplateId}
                                  value={
                                    formData.contractTemplateId
                                      ? getValue(
                                          contractsTemplates,
                                          formData.contractTemplateId,
                                        )
                                      : null
                                  }
                                  onChange={(e: SelectValue) =>
                                    setFormData({
                                      ...formData,
                                      contractTemplateId: e?.value
                                        ? Number(e.value)
                                        : null,
                                    })
                                  }
                                  options={parseValues(
                                    contractsTemplates ?? [],
                                  )}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className='filter-item'>
                                <Input
                                  labelText={true}
                                  inlineLabel={'Contract'}
                                  type={'text'}
                                  value={formData?.title ?? ''}
                                  disabled={
                                    (contract &&
                                      contract?.signStatus === 'completed') ??
                                    false
                                  }
                                  onChange={e =>
                                    setFormData({
                                      ...formData,
                                      title: e.target.value,
                                    })
                                  }
                                  error={formDataErrors?.title}
                                />
                              </div>
                            </Col>
                          </Row>
                          {contractTemplateFields?.length > 0 ? (
                            <Row className='fieldset-form'>
                              {filterByGroup(contractTemplateFields)}
                            </Row>
                          ) : null}
                          {contractTemplate?.fields &&
                          contractTemplate?.fields?.length > 0 ? (
                            <Row className='fieldset-form'>
                              {filterByGroup(contractTemplate?.fields, true)}
                            </Row>
                          ) : null}
                          <Row className='fieldset-form'>
                            <Col md={12}>
                              <h2 className='fieldset-heading'>Contacte</h2>
                            </Col>
                            <Col md={4}>
                              <Input
                                labelText={true}
                                inlineLabel={'Email agent'}
                                type={'email'}
                                value={formData.employeeEmail ?? ''}
                                disabled={
                                  (contract &&
                                    contract?.signStatus === 'completed') ??
                                  false
                                }
                                error={formDataErrors?.employeeEmail}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    employeeEmail: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col md={4}>
                              <Input
                                labelText={true}
                                inlineLabel={'Email client'}
                                type={'email'}
                                value={formData.clientEmail ?? ''}
                                error={formDataErrors?.clientEmail}
                                disabled={
                                  (contract &&
                                    contract?.signStatus === 'completed') ??
                                  false
                                }
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    clientEmail: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col md={4}>
                              <Input
                                labelText={true}
                                inlineLabel={'Email agency'}
                                type={'email'}
                                disabled={true}
                                value={formData.agencyEmail ?? ''}
                                error={formDataErrors?.agencyEmail}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    agencyEmail: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col md={12}>
                              <div className='ads-contracts-buttons'>
                                {(!contract ||
                                  contract?.signStatus !== 'completed') && (
                                  <button
                                    className='button button-border'
                                    onClick={e => {
                                      e.preventDefault();
                                      submit();
                                    }}
                                  >
                                    Salveaza draft
                                  </button>
                                )}
                                <button
                                  className='button button-border'
                                  disabled={!formData.contractTemplateId}
                                  onClick={e => {
                                    e.preventDefault();
                                    setShowPreviewModal(true);
                                  }}
                                >
                                  Previzualizare
                                </button>
                                {(!contract ||
                                  contract?.signStatus !== 'completed') && (
                                  <button
                                    className='button button-blue'
                                    onClick={e => {
                                      e.preventDefault();
                                      signContract();
                                    }}
                                  >
                                    Semneaza <IconPencilWriting />
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <MissingAccess />
          )}
        </div>
      </div>
      {showPreviewModal && (
        <ModalPDF
          showModal={showPreviewModal}
          item={formData}
          url={formData.signedUrl}
          hideModal={() => setShowPreviewModal(false)}
          isDownload
        />
      )}
      {showScanModal && (
        <ModalScanContract
          showModal={showScanModal}
          handlePrefillData={handlePrefillData}
          hideModal={() => setShowScanModal(false)}
        />
      )}
      {showSignModal && (
        <ModalSign
          showModal={showSignModal !== null}
          id={showSignModal}
          hideModal={() => setShowSignModal(null)}
        />
      )}
    </>
  );
};

export default AddContract;
