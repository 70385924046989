import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ActivityCalendar from './containers/ActivityCalendar';
import ActivityList from './containers/ActivityList';
import AddActivity from './containers/AddActivity';
import AddAgent from './containers/AddAgent';
import AddContact from './containers/AddContact';
import AddContract from './containers/AddContract';
import AddContractTemplate from './containers/AddContractTemplate';
import AddProperty from './containers/AddProperty/AddProperty';
import AddRequest from './containers/AddRequest/AddRequest';
import AddResidentialComplex from './containers/AddResidentialComplex';
import AgentProfile from './containers/AgentProfile';
import AgentsPage from './containers/AgentsPage';
import Announces from './containers/Announces';
import AnnouncesAlerts from './containers/AnnouncesAlerts';
import AnnouncesTags from './containers/AnnouncesTags';
import CompanyList from './containers/CompanyList';
import Contacts from './containers/Contacts';
import ContractTemplates from './containers/ContractTemplates';
import Contracts from './containers/Contracts';
import EditCompany from './containers/EditCompany';
import EditContact from './containers/EditContact';
import EditResidentialComplex from './containers/EditResidentialComplex';
import EmailTemplates from './containers/EmailTemplates';
import ForgotPassword from './containers/ForgotPassword';
import Home from './containers/Home';
import Login from './containers/Login';
import MapPage from './containers/MapPage';
import NewEvaluation from './containers/NewEvaluation';
import ParticularRequests from './containers/ParticularRequests';
import PriceEvaluation from './containers/PriceEvaluation';
import Property from './containers/Property';
import PropertyList from './containers/PropertyList';
import PropertyUpdates from './containers/PropertyUpdates';
import PublicProperty from './containers/PublicProperty';
import Reports from './containers/Reports';
import ReportsAgents from './containers/ReportsAgents';
import ReportsEstates from './containers/ReportsEstates';
import Request from './containers/Request';
import RequestsList from './containers/RequestsList';
import ResetPassword from './containers/ResetPassword';
import ResidentialComplexes from './containers/ResidentialComplexes';
import ScanDocument from './containers/ScanDocument';
import SlotAllocation from './containers/SlotAllocation';
import SlotsUse from './containers/SlotsUse';
import { getUserProfile } from './redux/auth/authThunk';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { getLanguages } from './redux/language/languageThunk';

function Navigation() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { auth } = useAppSelector(state => state.auth);
  const { languages } = useAppSelector(state => state.languages);

  useEffect(() => {
    checkAuthUser();
    if (languages == null || languages.length === 0) {
      dispatch(getLanguages({ limit: 20, offset: 1, sort: 'priority' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuthUser = async () => {
    if (localStorage.getItem('userId')) {
      await dispatch(getUserProfile());
    }

    setLoading(false);
  };

  if (loading) {
    return;
  }

  return (
    <BrowserRouter>
      <Routes>
        {auth ? (
          <>
            <Route
              path='/login'
              element={
                <Navigate
                  to='/'
                  state={{ previousLocationPathname: '/login' }}
                />
              }
            />

            <Route path='/' element={<Home />} />
            <Route path='/add-property' element={<Property />} />
            <Route path='/property-list' element={<PropertyList />} />
            <Route path='/properties/:id' element={<AddProperty />} />
            <Route
              path='/contacts/:id'
              element={<EditContact />}
              key={'edit'}
            />
            <Route path='/company-list' element={<CompanyList />} />
            <Route path='/add-contact' element={<AddContact />} key={'add'} />
            <Route path='/email-templates' element={<EmailTemplates />} />

            <Route path='/ads' element={<Announces />} />
            <Route path='/ads/:searchId' element={<Announces />} />
            <Route path='/tag-ads/:labelId' element={<Announces />} />
            <Route path='/requests' element={<RequestsList />} />
            <Route path='/ads-tags' element={<AnnouncesTags />} />
            <Route path='/ads-alerts' element={<AnnouncesAlerts />} />
            <Route path='/slots-use' element={<SlotsUse />} />
            <Route path='/map' element={<MapPage />} />

            <Route path='/add-contacts' element={<AddContact />} />
            <Route
              path='/my-contacts'
              element={<Contacts key={'my-contacts'} />}
            />
            <Route
              path='/agency-contacts'
              element={<Contacts key={'agency-contacts'} />}
            />
            <Route path='/companies' element={<CompanyList />} />
            <Route path='/edit-company/:companyId' element={<EditCompany />} />

            <Route path='/add-property' element={<AddProperty />} />
            <Route
              path='/my-properties'
              element={<PropertyList title='Proprietatile Mele' key={'my'} />}
            />
            <Route
              path='/agency-properties'
              element={
                <PropertyList title='Proprietatile Agentiei' key={'agency'} />
              }
            />
            <Route path='/update-properties' element={<PropertyUpdates />} />
            <Route
              path='/residential-complexes'
              element={<ResidentialComplexes />}
            />
            <Route
              path='/add-residential-complex'
              element={<AddResidentialComplex />}
            />
            <Route
              path='/residential-complexes/:id'
              element={<EditResidentialComplex />}
            />

            <Route path='/add-request' element={<Request />} />
            <Route path='/requests/:id' element={<AddRequest />} />
            <Route
              path='/my-requests'
              element={<RequestsList title='Solicitările Mele' key={'my'} />}
            />
            <Route
              path='/company-requests'
              element={
                <RequestsList title='Solicitări Agentiei' key={'agency'} />
              }
            />
            <Route path='/email-offer-templates' element={<EmailTemplates />} />

            <Route path='/add-activity' element={<AddActivity key={'add'} />} />
            <Route
              path='/activities/:id'
              element={<AddActivity key={'edit'} />}
            />
            <Route path='/my-activities' element={<ActivityList />} />
            <Route path='/calendar' element={<ActivityCalendar />} />

            <Route path='/reports' element={<Reports />} />
            <Route path='/agents-statistics' element={<ReportsAgents />} />
            <Route path='/estates-demands' element={<ReportsEstates />} />

            <Route path='/add-agent' element={<AddAgent />} />
            <Route path='/edit-agent/:agentId' element={<AddAgent />} />
            <Route path='/agents' element={<AgentsPage />} />
            <Route path='/slots' element={<SlotAllocation />} />
            <Route path='/my-profile' element={<AgentProfile />} />
            <Route path='/contracts' element={<Contracts />} />
            <Route path='/add-contract' element={<AddContract key={'add'} />} />
            <Route
              path='/edit-contract/:id'
              element={<AddContract key={'edit'} />}
            />
            <Route path='/contract-templates' element={<ContractTemplates />} />
            <Route
              path='/add-contract-template'
              element={<AddContractTemplate key={'add'} />}
            />
            <Route
              path='/edit-contract-template/:id'
              element={<AddContractTemplate key={'edit'} />}
            />
            <Route path='/price-evaluation' element={<PriceEvaluation />} />
            <Route
              path='/new-evaluation'
              element={<NewEvaluation key={'add'} />}
            />
            <Route
              path='/edit-evaluation/:id'
              element={<NewEvaluation key={'edit'} />}
            />
            <Route
              path='/particular-request'
              element={<ParticularRequests />}
            />
            <Route path='/scan-document/:token' element={<ScanDocument />} />
            <Route path='/contracts/sign/:uuid' element={<div></div>} />
            <Route path='/public/properties/:id' element={<PublicProperty />} />
          </>
        ) : (
          <>
            <Route path='/*' element={<Navigate to='/login' />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/scan-document/:token' element={<ScanDocument />} />
            <Route path='/contracts/sign/:uuid' element={<div></div>} />
            <Route path='/public/properties/:id' element={<PublicProperty />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
