import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Filters,
  PropertySubmit,
  PropertyUpdate,
} from '../../models/Properties';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export const getProperties = createAsyncThunk<any, Filters | any>(
  'auth/getProperties',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/properties', { params });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getUsersProperties = createAsyncThunk<any, Filters | any>(
  'auth/getUsersProperties',
  async (params, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/users/properties', {
        params,
      });
      return {
        data: response.data,
        totalCount: response.headers['x-total-count'] ?? response.data?.length,
      };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getProperty = createAsyncThunk<any, string>(
  'auth/getProperty',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/properties/' + id);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeProperties = createAsyncThunk<string, string>(
  'auth/removeProperties',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete('api/v1/crm/properties', {
        params: {
          ids: ids,
        },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const arhiveProperties = createAsyncThunk<string, string>(
  'auth/arhiveProperties',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/properties/archive?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const restoreProperties = createAsyncThunk<string, string>(
  'auth/restoreProperties',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/properties/restore?ids=' + ids,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const createProperty = createAsyncThunk<any, PropertySubmit>(
  'auth/createProperty',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/crm/properties', data);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const cloneProperty = createAsyncThunk<any, number>(
  'auth/cloneProperty',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/properties/' + id + '/clone',
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const updateProperty = createAsyncThunk<any, PropertyUpdate>(
  'auth/updateProperty',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.put(
        'api/v1/crm/properties/' + data.id,
        data.form,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// filters

export const getPropertyFilters = createAsyncThunk<any, number | undefined>(
  'auth/getPropertyFilters',
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/filters', {
        params: { category: categoryId, limit: -1, type: 'PROPERTY' },
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// filters

export const getPricesTypes = createAsyncThunk<any>(
  'auth/getPricesTypes',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/prices-types');
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// description

export const generateDescription = createAsyncThunk<any, any>(
  'auth/generateDescription',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(
        'api/v1/crm/properties/generate/description',
        data,
      );
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

// counts

export const getUserCounts = createAsyncThunk<any>(
  'auth/getUserCounts',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/users/properties/counts');
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getCounts = createAsyncThunk<any>(
  'auth/getCounts',
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/properties/counts');
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const exportProperties = createAsyncThunk<any, any>(
  'crm/exportProperties',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.post('api/v1/crm/properties/export', {
        ids: data,
      });
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getPublicProperty = createAsyncThunk<any, string>(
  'property/getPublicProperty',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/public/properties/' + id);
      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
