import React from 'react';
import './FilterListHeaderContractTemplates.scss';

interface Props {
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  sortItems?: (field: string) => void;
  sort?: string;
}

const FilterListHeaderContractTemplates: React.FC<Props> = ({
  filtersForm,
  setFiltersForm,
  sort,
  sortItems,
}) => {
  return (
    <div className='filter-list-container contracts-templates-list-item'>
      <form>
        <div
          className={`filter-item arrows-label-icon ${sort === 'id' ? 'active-bottom' : ''} ${sort === '-id' ? 'active-top' : ''}`}
        >
          <label
            onClick={() => {
              if (sortItems)
                sort === 'id'
                  ? sortItems('-id')
                  : sort === '-id'
                    ? sortItems('')
                    : sortItems('id');
            }}
          >
            ID
          </label>
        </div>
        <div className='filter-item'>
          <label>Data adaugare</label>
        </div>
        <div className='filter-item'>
          <label>Denumirea sablonului</label>
        </div>

        <div className='filter-item'>
          <label>Actiuni</label>
        </div>
      </form>
    </div>
  );
};
export default FilterListHeaderContractTemplates;
