import { createAsyncThunk } from '@reduxjs/toolkit';
import { FormItem } from '../../components/SlotAllocationTable/SlotAllocationTable';
import { Partner, UserSlotsData } from '../../models/Partner';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

export type PartnersReponse = {
  partners: Partner[];
  totalCount: number;
};

export const getPartners = createAsyncThunk<PartnersReponse, any>(
  'crm/getPartners',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/partners', {
        params: data,
      });
      const totalCount = response.headers['x-total-count'];

      return { partners: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getSlots = createAsyncThunk<UserSlotsData, number>(
  'crm/getSlots',
  async (id, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(
        'api/v1/crm/partners/' + id + '/slots',
      );

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const saveSlots = createAsyncThunk<
  any,
  { id: number; data: FormItem[] }
>('crm/saveSlots', async (data, { rejectWithValue }) => {
  try {
    const response = await appAxios.put(
      'api/v1/crm/partners/' + data.id + '/slots',
      data.data,
    );

    return response.data;
  } catch (error: any) {
    return handleError(error, rejectWithValue);
  }
});
