import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';
import { OrganizationSlotStatistic } from '../../models/OrganizationSlotsStatistics';

export const getOrganizationSlotsStatistics = createAsyncThunk<OrganizationSlotStatistic[], any>(
  'users/getOrganizationSlotsStatistics',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get(`api/v1/crm/organizations/slots-statistics`, {
        params: data,
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);
