import { createSlice } from '@reduxjs/toolkit';
import { OrganizationSlotStatistic } from '../../models/OrganizationSlotsStatistics';
import { getOrganizationSlotsStatistics } from './organizationSlotsStatisticsThunk';

type OrganizationSlotStatisticState = {
  statistics: OrganizationSlotStatistic[];
  loading: boolean;
  error: any;
};

const initialState: OrganizationSlotStatisticState = {
  statistics: [],
  loading: false,
  error: null,
};

const organizationSlotsStatisticsSlice = createSlice({
  name: 'organizationSlotsStatistics',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getOrganizationSlotsStatistics.pending, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(
        getOrganizationSlotsStatistics.fulfilled,
        (state, { payload }) => {
          state.statistics = payload;
          state.loading = false;
          state.error = null;
        },
      )
      .addCase(getOrganizationSlotsStatistics.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = organizationSlotsStatisticsSlice.actions;

export default organizationSlotsStatisticsSlice.reducer;
