import { createSlice } from '@reduxjs/toolkit';
import { Partner, UserSlotsData } from '../../models/Partner';
import { getPartners, getSlots } from './partnersThunk';

type PartnerState = {
  partners: Partner[];
  slots: UserSlotsData | null;
  loading: boolean;
slotsLoading:boolean;
  error: any;
  count: number;
};

const initialState: PartnerState = {
  partners: [],
  slots: null,
  count: 0,
  loading: false,
  slotsLoading:false,
  error: null,
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getPartners.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPartners.fulfilled, (state, { payload }) => {
        state.partners = payload.partners;
        state.count = +payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getSlots.pending, state => {
        state.slotsLoading = true;
        state.error = null;
      })
      .addCase(getSlots.fulfilled, (state, { payload }) => {
        state.slots = payload;
        state.slotsLoading = false;
        state.error = null;
      })
      .addCase(getSlots.rejected, (state, action) => {
        state.error = action.payload;
        state.slotsLoading = false;
      });
  },
});

export const { clearError } = partnersSlice.actions;

export default partnersSlice.reducer;
