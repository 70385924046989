import React, { useEffect, useState } from 'react';
import DropdownBrands from '../../components/DropdownBrands';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import SlotAllocationTable from '../../components/SlotAllocationTable';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPartners, getSlots } from '../../redux/partners/partnersThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Partner } from '../../models/Partner';
import { Permissions } from '../../models/Permissions';
import './SlotAllocation.scss';

interface Props {}

const SlotAllocation: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { partners } = useAppSelector(
    state => state.partners,
  );
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(
    partners[0],
  );
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AlocateSlot),
  );

  useEffect(() => {
    dispatch(getPartners({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPartner?.id) {
      dispatch(getSlots(selectedPartner.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Alocare sloturi'
          subtitle='Lista Etichetelor adaugate in sectiunea de ImoAP'
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <div className='display-flex flex-column slots-container'>
            <div className='slot-dropdown'>
              <DropdownBrands
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
              />
            </div>
            <div className='display-flex flex-slot-allocation'>
              <SlotAllocationTable selectedPartner={selectedPartner}/>
            </div>
          </div>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default SlotAllocation;
