import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import {
  generateQrCode,
  getCiToken,
  scanCi,
} from '../../redux/contracts/contractsThunk';
import { useAppDispatch } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import IconTimes from '../../icons/IconTimes';
import Spinner from '../Spinner';
import './ModalScanContract.scss';

function isMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /mobile|android|iphone|ipad|ipod|windows phone/.test(userAgent);
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showModal: boolean;
  handlePrefillData: (data: any) => void;
  hideModal: () => void;
}

const ModalScanContract: React.FC<Props> = ({
  showModal,
  handlePrefillData,
  hideModal,
}) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();

  const [qrCode, setQrCode] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    getQrCode();

    if (isMobile()) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isMobile() && token) {
      const intervalId = setInterval(
        async () => getCiTokenFunction(token),
        10000,
      );
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [token]);

  const getQrCode = async () => {
    try {
      const resultQr = await dispatch(generateQrCode({}));
      unwrapResult(resultQr);

      setQrCode(resultQr.payload?.data.qrCode);
      setToken(resultQr.payload?.data.token);
    } catch (err: any) {
      hideModal();
      diplayErrorMessages(err);
    } finally {
      setLoading(false);
    }
  };

  const getCiTokenFunction = async (initialToken: string) => {
    if (initialToken) {
      try {
        const resultToken = await dispatch(getCiToken(initialToken));
        unwrapResult(resultToken);

        if (resultToken?.payload?.data?.data) {
          handlePrefillData(resultToken?.payload?.data?.data);
          toast.success('Actul a fost scanat cu succes!');
          hideModal();
        }
      } catch (err: any) {
        diplayErrorMessages(err);
      }
    }
  };

  const scanCiFunction = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('token', token);

      const resultScan = await dispatch(scanCi(formData));
      unwrapResult(resultScan);

      if (resultScan?.payload?.data?.data) {
        handlePrefillData(resultScan?.payload?.data?.data);
      }
      toast.success('Actul a fost scanat cu succes!');
      hideModal();
    } catch (err: any) {
      hideModal();
      diplayErrorMessages(err);
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      scanCiFunction(file);
    }
  };

  const handleCancel = () => {
    hideModal();
    setQrCode('');
    setToken('');
    setLoading(false);
  };

  const handleFileInputFocus = () => {
    if (fileInputRef.current) {
      const input = fileInputRef.current;
      const blurListener = () => {
        if (!input.files?.length) {
          handleCancel();
        }
        input.removeEventListener('blur', blurListener);
      };
      input.addEventListener('blur', blurListener);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={hideModal}
        animation={false}
        className={`scan-modal ${isMobile() ? 'mobile' : ''}`}
      >
        <button
          className='close-modal'
          aria-label='Închide'
          onClick={hideModal}
        >
          <IconTimes />
        </button>
        <Modal.Body>
          {!isMobile() ? (
            <div className='scan-modal__qr'>
              <h3>
                Scaneaza cu telefonul pentru a activa procesul de scanare a
                actului de identitate
              </h3>
              {loading ? <Spinner /> : <img src={qrCode} alt='QR code' />}
            </div>
          ) : (
            <div className='scan-modal__mobile'>
              <input
                type='file'
                accept='image/*'
                capture='environment'
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onClick={e => (e.currentTarget.value = '')}
                onFocus={handleFileInputFocus}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalScanContract;
