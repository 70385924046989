import { unwrapResult } from '@reduxjs/toolkit';
import * as locale from 'date-fns/locale';
import moment from 'moment/moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactDatePicker from 'react-datepicker';
import FormSelect from '../../components/FormSelect';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import UsageChart from '../../components/UsageChart';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getOrganizationSlotsStatistics } from '../../redux/organizationSlotsStatistics/organizationSlotsStatisticsThunk';
import { getPartners } from '../../redux/partners/partnersThunk';
import { getUsers } from '../../redux/users/usersThunk';
import useHelper, {
  getValue,
  haveUserPermission,
  parseValues,
} from '../../helpers/useHelper';
import { OrganizationSlotStatisticFilters } from '../../models/OrganizationSlotsStatistics';
import { Package } from '../../models/Partner';
import { Permissions } from '../../models/Permissions';
import { SelectValue } from '../../models/Properties';
import './SlotsUse.scss';

const SlotsUse: React.FC = () => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { partners } = useAppSelector(state => state.partners);
  const { users } = useAppSelector(state => state.users);
  const { statistics } = useAppSelector(
    state => state.organizationSlotsStatistics,
  );
  const defaultFilter = {
    dateStart: moment()
      ?.subtract(2, 'weeks')
      ?.hours(0)
      ?.minutes(0)
      ?.seconds(0)
      ?.format('YYYY-MM-DD HH:mm:ss'),
    dateEnd: moment()
      ?.hours(23)
      ?.minutes(59)
      ?.seconds(59)
      ?.format('YYYY-MM-DD HH:mm:ss'),
    partnerId: undefined,
    employeeId: undefined,
    partnerPackages: undefined,
  };
  const [formData, setFormData] =
    useState<OrganizationSlotStatisticFilters>(defaultFilter);
  const [packages, setPackages] = useState<Package[]>([]);
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.SlotUsage),
  );
  const [showCalendar, setShowCalendar] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const myRef = useRef<any>();

  useEffect(() => {
    dispatch(getPartners({}));
    dispatch(getUsers({}));
    getStatisticsData(formData);

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !showCalendar &&
      (formData?.dateStart !== formData.dateStart ||
        formData?.dateEnd !== formData.dateEnd)
    ) {
      setFormData(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCalendar]);

  useEffect(() => {
    if (formData.partnerId !== undefined && formData.partnerId !== null) {
      const partnerId = formData?.partnerId ?? 0;
      setPackages(
        partners.find(item => item.id === +partnerId)?.packages ?? [],
      );
    } else {
      setPackages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.partnerId]);

  const getStatisticsData = async (data: OrganizationSlotStatisticFilters) => {
    try {
      const response = await dispatch(getOrganizationSlotsStatistics(data));
      unwrapResult(response);
    } catch (error) {
      diplayErrorMessages(error);
    } finally {
      setSubmitLoading(false);
      setResetLoading(false);
    }
  };

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const handleSelect = (ranges: any) => {
    setFormData({
      ...formData,
      dateStart: moment(ranges?.selection?.startDate)
        ?.hours(0)
        ?.minutes(0)
        ?.seconds(0)
        ?.format('YYYY-MM-DD HH:mm:ss'),
      dateEnd: moment(ranges?.selection?.endDate)
        ?.hours(23)
        ?.minutes(59)
        ?.seconds(59)
        ?.format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const resetForm = () => {
    setResetLoading(true);
    setFormData(defaultFilter);
    getStatisticsData(defaultFilter);
  };

  const handleCheckboxChange = (id: number) => {
    if (formData?.partnerPackages?.includes(id)) {
      const partnerPackages = formData?.partnerPackages?.filter(
        item => item !== id,
      );
      setFormData(prevState => ({
        ...prevState,
        partnerPackages: partnerPackages,
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        partnerPackages: [...(prevState?.partnerPackages ?? []), id],
      }));
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitLoading(true);
    getStatisticsData(formData);
  };

  return (
    <>
      <div className='flex-container display-flex '>
        <SideNav />
        <div className='display-flex flex-column main-wrapper'>
          <Header
            title='Utilizare sloturi'
            subtitle='Vezi statistici despre utilizarea sloturilor'
            isbutton={false}
            backBtn={false}
            btnText=''
          />

          {havePermission ? (
            <>
              <div className='section-container slots-use-section'>
                <div className='bg-white slots-use-left'>
                  <h2 className='heading-block'>Filter</h2>
                  <form
                    action=''
                    className='form-block'
                    onSubmit={handleSubmit}
                  >
                    <div className='form-item'>
                      <FormSelect
                        labelText={true}
                        placeholder='Select Option'
                        inlineLabel={'Partener'}
                        isSearchable={true}
                        isClearable={true}
                        value={
                          formData?.partnerId
                            ? getValue(partners, formData?.partnerId)
                            : null
                        }
                        options={
                          partners.length > 0 ? parseValues(partners) : []
                        }
                        onChange={(e: SelectValue) =>
                          setFormData(prevState => ({
                            ...prevState,
                            partnerId: e?.value,
                          }))
                        }
                      />
                    </div>
                    <div className='date-picker'>
                      <div className='flex-input range-date date-column'>
                        <label>Selecteaza perioda</label>
                        {showCalendar && (
                          <div className='range-calendar' ref={myRef}>
                            <DateRangePicker
                              ranges={[
                                {
                                  startDate: formData?.dateStart
                                    ? new Date(formData?.dateStart)
                                    : undefined,
                                  endDate: formData?.dateEnd
                                    ? new Date(formData?.dateEnd)
                                    : undefined,
                                  key: 'selection',
                                },
                              ]}
                              locale={locale.enUS}
                              onChange={handleSelect}
                            />
                          </div>
                        )}
                        <ReactDatePicker
                          placeholderText={
                            formData?.dateStart && formData?.dateEnd
                              ? `${moment(formData?.dateStart).format('YYYY-MM-DD')} - ${moment(formData?.dateEnd).format('YYYY-MM-DD')}`
                              : 'De la/Pana la'
                          }
                          value={
                            formData?.dateStart &&
                            formData?.dateEnd &&
                            !showCalendar
                              ? `${moment(formData?.dateStart).format('YYYY-MM-DD')} - ${moment(formData?.dateEnd).format('YYYY-MM-DD')}`
                              : ''
                          }
                          selected={null}
                          onCalendarOpen={() => setShowCalendar(true)}
                          onChange={() => {}}
                        />
                      </div>
                    </div>

                    <div className='form-item'>
                      <FormSelect
                        labelText={true}
                        placeholder='Select Option'
                        inlineLabel={'Agent'}
                        isSearchable={true}
                        isClearable={true}
                        value={
                          formData?.employeeId
                            ? getValue(users, formData?.employeeId)
                            : null
                        }
                        options={users.length > 0 ? parseValues(users) : []}
                        onChange={(e: SelectValue) =>
                          setFormData(prevState => ({
                            ...prevState,
                            employeeId: e?.value,
                          }))
                        }
                      />
                    </div>

                    {packages.length > 0 ? (
                      <Form.Group className='form-group' id='type-news'>
                        <label htmlFor='' className='form-item-group-label'>
                          Pachete
                        </label>
                        {packages.map(item => (
                          <Form.Check
                            key={item.id}
                            type='checkbox'
                            label={item?.title}
                            checked={formData?.partnerPackages?.includes(
                              item.id,
                            )}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        ))}
                      </Form.Group>
                    ) : (
                      ''
                    )}

                    <div className='buttons-group'>
                      <button
                        onClick={resetForm}
                        className='button-blue reset-btn'
                        type='button'
                        disabled={submitLoading || resetLoading}
                      >
                        {resetLoading ? (
                          <Spinner className='btn-blue-spinner' />
                        ) : null}
                        Reseteaza
                      </button>
                      <button
                        disabled={resetLoading || submitLoading}
                        className='button-blue button-md submit-btn'
                      >
                        {submitLoading ? (
                          <Spinner className='btn-blue-spinner' />
                        ) : null}
                        Filtreaza
                      </button>
                    </div>
                  </form>
                </div>
                <div className='bg-white slots-use-right '>
                  <UsageChart key={statistics.length} data={statistics} />
                </div>
              </div>
            </>
          ) : (
            <MissingAccess />
          )}
        </div>
      </div>
    </>
  );
};

export default SlotsUse;
